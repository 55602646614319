import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  TextInput,
  FlatList,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { ActivityIndicator, Appbar } from 'react-native-paper'
import { userApi } from '@/Services/modules/users'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  ReviewCard,
  SpiritCard,
  ChipItem,
  WarningSignComponent,
  AdvertiseList,
} from '@/Components'
import { Colors } from '@/Theme/Variables'
import noteApi from '@/Services/modules/note'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, getGlobal } from '@/Store'
import { spiritActions } from '@/Store/Spirit'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { DownLoadSign, AdvertiseCarousel } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'
// import { useTranslation } from 'react-i18next'
import { api } from '@/Services/api'
import { advertiseApi, AdvertiseSpaceEnum } from '@/Services/advertiseApi'

import Banner from './components/banner'

// 筆記分類項目名稱
enum CHIP_NAME {
  LATEST = '最新',
  POPULAR = '熱門',
}

// Tab 名稱
enum TAB_NAME {
  POPULAR_SPIRITS = 0,
  NOTES = 1,
  FOLLOWING = 2,
}

const HomeContainer = ({ navigation, scrollRef }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()

  //   const { t } = useTranslation()
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  const { language } = useSelector((state: RootState) => state.theme)
  const { followingNotes, popular_spirits, popular_notes, latest_notes } =
    useSelector((state: RootState) => state.spirit.homePage)

  const { data: getMeData } = userApi.useGetMeQuery()

  const { data: siteMetaData } = api.useGetSiteMetaQuery()

  // NOTE: ai 助理開關由後端掌控 assistant
  const isAssistantEnabled = siteMetaData?.features?.assistant || false

  // 追蹤中的 feeds
  const [getFollowingNotesRequest, { isLoading: getFollowingNotesIsLoading }] =
    noteApi.useLazyGetFollowingNoteQuery()

  // 最新的酒、最熱門的筆記
  const [
    getRecommendationRequest,
    {
      data: recommendationData,
      isError: recommendationIsError,
      error: recommendationError,
    },
  ] = userApi.useLazyGetRecommendationFeedsQuery()

  // 最新的筆記
  const [getLatestNoteRequest] = noteApi.useLazyGetLatestNoteQuery()

  // 廣告資料
  const { data: advertiseData } = advertiseApi.useGetAdvertiseInfoQuery(
    {
      space: [
        AdvertiseSpaceEnum.HOME_BANNER,
        AdvertiseSpaceEnum.HOME_TAB_POPULAR_LIST,
      ],
    },
    { refetchOnMountOrArgChange: 10 },
  )

  const [index, setIndex] = useState(TAB_NAME.POPULAR_SPIRITS)
  const [noteTab, setNoteTab] = useState<CHIP_NAME>(CHIP_NAME.LATEST)
  const [notesListData, setNotesListData] = useState<any>()
  const [followingPage, setFollowingPage] = useState<number>(1)

  const initFetch = useCallback(() => {
    if (getMeData) {
      getFollowingNotesRequest({
        page: 1,
      })
    }
    getLatestNoteRequest({})
    getRecommendationRequest({
      type: 'popular_spirits',
    })
    getRecommendationRequest({
      type: 'popular_notes',
    })
  }, [
    getMeData,
    getLatestNoteRequest,
    getFollowingNotesRequest,
    getRecommendationRequest,
  ])

  const initFetchFollowingNotes = useCallback(() => {
    if (getMeData) {
      getFollowingNotesRequest({
        page: 1,
      })
    }
  }, [getMeData, getFollowingNotesRequest])

  const initFetchPopularSpirits = useCallback(() => {
    getRecommendationRequest({
      type: 'popular_spirits',
    })
  }, [getRecommendationRequest])

  const initFetchPopularNotes = useCallback(() => {
    getRecommendationRequest({
      type: 'popular_notes',
    })
  }, [getRecommendationRequest])

  const initFetchLatestNotes = useCallback(() => {
    getLatestNoteRequest({})
  }, [getLatestNoteRequest])

  // 切換 tab 重新撈資料
  useEffect(() => {
    if (index || noteTab) {
      dispatch(spiritActions.clearHomePagePostFeedsData())
      if (index === TAB_NAME.POPULAR_SPIRITS) {
        initFetchPopularSpirits()
      }
      if (index === TAB_NAME.NOTES) {
        if (noteTab === CHIP_NAME.LATEST) {
          initFetchLatestNotes()
        }
        if (noteTab === CHIP_NAME.POPULAR) {
          initFetchPopularNotes()
        }
      }
      if (index === TAB_NAME.FOLLOWING) {
        initFetchFollowingNotes()
      }
    }
  }, [
    noteTab,
    index,
    dispatch,
    initFetchPopularSpirits,
    initFetchLatestNotes,
    initFetchPopularNotes,
    initFetchFollowingNotes,
  ])

  // 因為系統會在凌晨 3am 計算熱門文章與酒，所以要透過 X-Feed-Version 跟後端比對目前的 feed 版本，如果不是的話會回 400 VersionIsNotMatched，此時清空資料重新撈取
  useEffect(() => {
    if (recommendationIsError) {
      const errorData = recommendationError as any
      const message = errorData?.data?.errors?.[0]?.message
      if (message === 'VersionIsNotMatched') {
        dispatch(spiritActions.clearHomePagePostFeedsData())
        initFetch()
      }
    }
  }, [dispatch, initFetch, recommendationError, recommendationIsError])

  // 精選筆記，切換 tab
  useEffect(() => {
    if (noteTab === CHIP_NAME.LATEST) {
      setNotesListData(latest_notes?.data)
    }
    if (noteTab === CHIP_NAME.POPULAR) {
      const data = popular_notes?.data.map(item => item?.note)
      setNotesListData(data)
    }
  }, [noteTab, latest_notes, popular_notes])

  const handleClickCard = async (id: string, titleUrlSlug: string) => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug: titleUrlSlug,
      id,
    })
    await AsyncStorage.setItem('previousScreen', MainPagesEnum.Home)
  }

  const handleFetchMoreData = (
    type:
      | 'followingNotes'
      | 'popular_notes'
      | 'popular_spirits'
      | 'latest_notes',
  ) => {
    if (
      type === 'followingNotes' &&
      followingNotes?.meta?.currentPage !== followingNotes?.meta?.totalPages &&
      followingNotes?.meta?.totalPages !== followingPage &&
      getMeData
    ) {
      const nextPage = followingPage + 1
      getFollowingNotesRequest({
        page: nextPage,
      })
      setFollowingPage(nextPage)
    }
    if (type === 'popular_notes' && popular_notes?.meta?.nextCursor) {
      getRecommendationRequest({
        type: 'popular_notes',
        cursor: popular_notes?.meta?.nextCursor,
        x_feed_version: recommendationData?.x_feed_version || '',
      })
    }
    if (type === 'popular_spirits' && popular_spirits?.meta?.nextCursor) {
      getRecommendationRequest({
        type: 'popular_spirits',
        cursor: popular_spirits?.meta?.nextCursor,
        x_feed_version: recommendationData?.x_feed_version || '',
      })
    }
    if (type === 'latest_notes' && latest_notes?.meta?.nextCursor) {
      getLatestNoteRequest({
        cursor: latest_notes?.meta?.nextCursor,
      })
    }
  }

  const renderPopularSpiritItem = ({ item }: any) => {
    // 如果當天被刪除的資料，會回傳 null 而不是都不回傳
    if (item === null) {
      return null
    }
    const {
      title,
      imageUrl,
      ratings,
      brand,
      id,
      titleUrlSlug,
      collected,
      marketPrice,
      flavors,
    } = item?.spirit
    return (
      <SpiritCard
        id={id}
        title={title}
        onClick={() => handleClickCard(id, titleUrlSlug)}
        imageUrl={imageUrl}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
        collect={collected}
        marketPrice={marketPrice}
        showPrice={true}
        flavors={flavors?.topN}
        showFlavors={true}
      />
    )
  }

  const renderPopularSpirit = () => {
    return (
      // 避免三個 tab 的高度會變成最高的 tab 的高度
      <View
        style={[
          Layout.fill,
          index !== TAB_NAME.POPULAR_SPIRITS && styles.hidden,
        ]}
      >
        <FlatList
          ref={scrollRef}
          contentContainerStyle={[Layout.fill]}
          data={popular_spirits.data || []}
          renderItem={renderPopularSpiritItem}
          refreshing
          keyExtractor={item => item?.spirit?.id?.toString()}
          onEndReached={() => handleFetchMoreData('popular_spirits')}
          scrollEnabled={!productImageLightBox?.visible}
          onEndReachedThreshold={0.5}
          getItemLayout={(data, index) => {
            // SpiritCard_HEIGHT 是每個 SpiritCard 的高度，必免返回此頁面時，位置跑掉
            return {
              length: SpiritCardHeight,
              offset: SpiritCardHeight * index,
              index,
            }
          }}
          ListHeaderComponent={() => renderHeaderContainer()}
          ListEmptyComponent={() => {
            return (
              <View
                style={[
                  Layout.fill,
                  Layout.alignItemsCenter,
                  Gutters.largeTPadding,
                ]}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
              </View>
            )
          }}
          ItemSeparatorComponent={({ leadingItem }) => {
            return (
              <AdvertiseList
                leadingItem={leadingItem}
                listData={popular_spirits.data}
                adData={
                  advertiseData?.data?.[
                    AdvertiseSpaceEnum.HOME_TAB_POPULAR_LIST
                  ] || []
                }
                space={AdvertiseSpaceEnum.HOME_TAB_POPULAR_LIST}
              />
            )
          }}
        />
      </View>
    )
  }

  // 筆記列表分類
  const chipList = useMemo(() => {
    return [CHIP_NAME.LATEST, CHIP_NAME.POPULAR]
  }, [])

  const handleClickClip = (title: string) => {
    setNoteTab(title as CHIP_NAME)
  }

  // 追蹤中的 notes
  const renderNotesItem = ({ item }: any) => {
    // 如果當天被刪除的資料，會回傳 null 而不是都不回傳
    if (item === null) {
      return null
    }
    const {
      id,
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      counters,
      likeClicked,
      image,
      spirit,
    } = item
    return (
      <View
        style={[
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ReviewCard
          id={id}
          // TODO: 這邊 spirit id 要請後端捕
          spiritId={id}
          content={content}
          rating={rating}
          likeClicked={likeClicked}
          createdAt={createdAt}
          updatedAt={updatedAt}
          counters={counters}
          flavors={flavors}
          user={user}
          showCTA
          language={language}
          getMeData={getMeData}
          imagePath={image?.url}
          showProductName={true}
          productName={spirit?.title}
          onPressLike={() => {
            dispatch(
              spiritActions.setHomePagePostFeedsLike({
                feeds:
                  noteTab === CHIP_NAME.POPULAR
                    ? 'popular_notes'
                    : 'latest_notes',
                id,
              }),
            )
          }}
        />
      </View>
    )
  }

  const renderNotes = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          ref={scrollRef}
          keyExtractor={(_item, index) => index.toString()}
          data={notesListData || []}
          renderItem={renderNotesItem}
          onEndReached={() => {
            if (noteTab === CHIP_NAME.POPULAR) {
              handleFetchMoreData('popular_notes')
            }
            if (noteTab === CHIP_NAME.LATEST) {
              handleFetchMoreData('latest_notes')
            }
          }}
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => (
            <View
              style={[
                Layout.fullWidth,
                { height: 1, backgroundColor: Colors.background.onSurface },
              ]}
            />
          )}
          ListHeaderComponent={() => renderHeaderContainer()}
          ListEmptyComponent={() => {
            return (
              <View
                style={[
                  Layout.fill,
                  Layout.alignItemsCenter,
                  Gutters.largeTPadding,
                ]}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
              </View>
            )
          }}
        />
      </View>
    )
  }

  // 追蹤中的 feeds
  const renderFollowingFeedItem = ({ item }: any) => {
    // 如果當天被刪除的資料，會回傳 null 而不是都不回傳
    if (item === null) {
      return null
    }
    const {
      id,
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      counters,
      likeClicked,
      image,
      spirit,
    } = item
    return (
      <View
        style={[
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ReviewCard
          id={id}
          // TODO: 這邊 spirit id 要請後端捕
          spiritId={id}
          content={content}
          rating={rating}
          likeClicked={likeClicked}
          createdAt={createdAt}
          updatedAt={updatedAt}
          counters={counters}
          flavors={flavors}
          user={user}
          showCTA
          language={language}
          getMeData={getMeData}
          showProductName={true}
          productName={spirit?.title}
          imagePath={image?.url}
          onPressLike={() => {
            dispatch(
              spiritActions.setHomePagePostFeedsLike({
                feeds: 'followingNotes',
                id,
              }),
            )
          }}
        />
      </View>
    )
  }

  const renderFollowingEmpty = () => {
    if (getFollowingNotesIsLoading) {
      return (
        <View
          style={[Layout.fill, Layout.alignItemsCenter, Gutters.largeTPadding]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View style={[Layout.fill, Layout.center, Gutters.largeTPadding]}>
        <Image
          source={Images.home_empty}
          resizeMode="contain"
          style={[
            Gutters.smallBMargin,
            {
              width: 73,
              height: 130,
            },
          ]}
        />
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.smallBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          尚未有好友發佈筆記
        </Text>
        <TouchableOpacity
          onPress={() => {
            setIndex(1)
          }}
        >
          <Text
            style={[Fonts.size16, Fonts.weight500, { color: Colors.primary }]}
          >
            前往精選筆記探索更多
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  const renderFollowed = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          ref={scrollRef}
          keyExtractor={item => item?.id?.toString()}
          data={followingNotes?.data || []}
          onEndReached={() => handleFetchMoreData('followingNotes')}
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => (
            <View
              style={[
                Layout.fullWidth,
                { height: 1, backgroundColor: Colors.background.onSurface },
              ]}
            />
          )}
          renderItem={renderFollowingFeedItem}
          ListEmptyComponent={renderFollowingEmpty}
          ListHeaderComponent={() => renderHeaderContainer()}
        />
      </View>
    )
  }

  const renderScene = () => {
    if (index === TAB_NAME.POPULAR_SPIRITS) {
      return renderPopularSpirit()
    }
    if (index === TAB_NAME.NOTES) {
      return renderNotes()
    }
    if (index === TAB_NAME.FOLLOWING) {
      return renderFollowed()
    }
  }

  const renderHeader = useCallback(() => {
    return (
      <Appbar.Header
        style={[
          Layout.fullWidth,
          Gutters.regularHPadding,
          Gutters.regularVPadding,
          styles.headerContainer,
        ]}
      >
        <View style={Layout.fullWidth}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size24,
              { color: Colors.secondaryText },
            ]}
          >
            搜尋烈酒
          </Text>
          <TouchableOpacity
            style={[
              Layout.row,
              Gutters.tinyTMargin,
              styles.searchInputContainer,
            ]}
            onPress={() => navigation.navigate(PagesEnum.SearchListContainer)}
          >
            <Image
              style={Layout.iconSize24}
              source={Images.home_search}
              resizeMode="contain"
            />
            <TextInput
              style={[styles.searchInput, { color: Colors.white }]}
              placeholder="搜尋今天喝的烈酒，例如 Jimbeam"
              placeholderTextColor={Colors.gray}
            />
          </TouchableOpacity>
        </View>
      </Appbar.Header>
    )
  }, [Fonts, Gutters, Images, Layout, navigation])

  const renderHeaderContainer = useCallback(() => {
    return (
      <>
        {renderHeader()}
        {/* 未登入的登入按鈕 */}
        {!getMeData && (
          <View style={Gutters.regularHPadding}>
            <View
              style={[
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                styles.loginContainer,
              ]}
            >
              <Text
                style={[
                  Fonts.bold,
                  Gutters.smallBMargin,
                  { color: Colors.white },
                ]}
              >
                註冊或登入以享受更多服務與功能
              </Text>
              <TouchableOpacity
                style={[Layout.colCenter, styles.loginBtn]}
                onPress={() =>
                  navigation.navigate(PagesEnum.LoginMainContainer)
                }
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size14,
                    Fonts.textCenter,
                    {
                      color: Colors.primaryText,
                    },
                  ]}
                >
                  登入或註冊
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        <Banner
          navigation={navigation}
          getMeData={getMeData}
          isAssistantEnabled={isAssistantEnabled}
        />
        {advertiseData?.data?.[AdvertiseSpaceEnum.HOME_BANNER] &&
          advertiseData?.data?.[AdvertiseSpaceEnum.HOME_BANNER].length > 0 && (
            <AdvertiseCarousel
              data={advertiseData?.data?.[AdvertiseSpaceEnum.HOME_BANNER]}
              space={AdvertiseSpaceEnum.HOME_BANNER}
            />
          )}
        <View style={[Gutters.regularHPadding]}>
          <Text
            style={[
              Fonts.size24,
              Fonts.weight700,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            新發現
          </Text>
        </View>
        <View style={[Layout.row, Layout.fullWidth]}>
          <TouchableOpacity
            style={[
              Layout.center,
              {
                width: '33%',
                height: 48,
                borderBottomColor: Colors.primary,
                borderBottomWidth: index === TAB_NAME.POPULAR_SPIRITS ? 4 : 0,
              },
            ]}
            onPress={() => setIndex(TAB_NAME.POPULAR_SPIRITS)}
          >
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                {
                  color:
                    index === TAB_NAME.POPULAR_SPIRITS
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.light.primary3,
                },
              ]}
            >
              熱門的酒
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              Layout.center,
              {
                width: '33%',
                height: 48,
                borderBottomColor: Colors.primary,
                borderBottomWidth: index === TAB_NAME.NOTES ? 4 : 0,
              },
            ]}
            onPress={() => setIndex(TAB_NAME.NOTES)}
          >
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                {
                  color:
                    index === TAB_NAME.NOTES
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.light.primary3,
                },
              ]}
            >
              精選筆記
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              Layout.center,
              {
                width: '33%',
                height: 48,
                borderBottomColor: Colors.primary,
                borderBottomWidth: index === TAB_NAME.FOLLOWING ? 4 : 0,
              },
            ]}
            onPress={() => setIndex(TAB_NAME.FOLLOWING)}
          >
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                {
                  color:
                    index === TAB_NAME.FOLLOWING
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.light.primary3,
                },
              ]}
            >
              追蹤中
            </Text>
          </TouchableOpacity>
        </View>
        {index === TAB_NAME.NOTES && (
          <View
            style={[
              Layout.row,
              Layout.alignItemsCenter,
              Gutters.smallVPadding,
              Gutters.regularHPadding,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {chipList.map(item => (
              <ChipItem
                key={item}
                title={item}
                selected={item === noteTab}
                handleClickClip={handleClickClip}
              />
            ))}
          </View>
        )}
      </>
    )
  }, [
    Fonts,
    Gutters,
    Layout,
    advertiseData?.data,
    chipList,
    getMeData,
    index,
    isAssistantEnabled,
    navigation,
    noteTab,
    renderHeader,
  ])

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.black }]}>
      <View style={[Layout.fill]}>{renderScene()}</View>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign bottom={55} />
    </View>
  )
}

const styles = StyleSheet.create({
  hidden: { maxHeight: 700 },
  padding32: {
    padding: 32,
  },
  headerContainer: {
    backgroundColor: Colors.black,
    marginBottom: 32,
    height: 72,
    paddingTop: 56,
  },
  searchInputContainer: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.background.default,
    borderRadius: 8,
    alignItems: 'center',
    paddingLeft: 12,
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  searchInput: {
    width: '100%',
    marginLeft: 4,
    outlineWidth: 0,
    outlineColor: 'transparent',
    outlineStyle: 'none',
  },
  loginContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 10,
    paddingVertical: 24,
    alignItems: 'center',
  },
  loginBtn: {
    backgroundColor: Colors.primary,
    width: '100%',
    height: 40,
    borderRadius: 8,
    paddingVertical: 8,
  },
})

export default HomeContainer
