/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import {
  CommonActions,
  createNavigationContainerRef,
} from '@react-navigation/native'

type RootStackParamList = {
  Startup: undefined
  Main: undefined
  ScanLoadingContainer: undefined
  CommentModal: undefined
  MySpiritContainer: undefined
  ProductDetailContainer: undefined
  ReviewNote: undefined
  ProductDetail: undefined
  ProfileMainContainer: undefined
  Home: undefined
  AIChatRoomContainer: undefined
  CampaignSite: { url: string }
}

export const navigationRef = createNavigationContainerRef<RootStackParamList>()

export function navigate(name: keyof RootStackParamList, params: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params)
  }
}

export function navigateAndReset(routes = [], index = 0) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index,
        routes,
      }),
    )
  }
}

export function navigateAndSimpleReset(name: string, index = 0) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index,
        routes: [{ name }],
      }),
    )
  }
}

export function getParam() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute()?.params
  }
}

export function getRoute() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute()
  }
}
