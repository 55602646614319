import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { View, Image, StyleSheet, Dimensions, Platform } from 'react-native'
import EventSource, { EventSourceListener } from 'react-native-sse'
import { ScrollView } from 'react-native-gesture-handler'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { MainPagesEnum } from '@/Navigators/Main'
import { PagesEnum } from '@/Navigators/Application'
import threadsApi from '@/Services/modules/threads'
import { getConfig } from '@/Util/global'
import { api } from '@/Services/api'
import { prefixProxyEndpoint } from '@/Services/api'
import {
  advertiseApi,
  AdvertiseSpaceEnum,
  DefaultAdvertiseItem,
} from '@/Services/advertiseApi'

import TextInputComponent, {
  TextInputComponentType,
} from './components/textInputComponent'
import CustomMessage, { MessageType } from './components/customMessage'
import { AIChatRoomMessage } from './types'
import TypingIndicator from './components/typingIndicator'

const SERVER_SENT_EVENT_URL =
  getConfig().ENV === 'local'
    ? prefixProxyEndpoint('/v1/assistants/threads/runs')
    : `${getConfig().API_URL}/v1/assistants/threads/runs`

enum ThreadRunProvider {
  OPENAI = 'openai',
  GOOGLE = 'google',
}

type AIChatRoomEvents =
  | 'message.delta' // 要處理的 event 之一，這 event 會帶有 GPT 的回覆內容
  | 'message.completed' // 要處理的 event 之一，這 event 代表 GPT 的回覆已結束，data 內帶有卡片資訊
  | 'done' // 表示該次對話完全結束，可以中斷該次連線了
  | 'error.happened' // 出錯了 code = 429, message = tooManyAttempts ,  code = 500, message = unknownError, code = 400, message = active_thread , 403 後端關閉 assistant 將使用者導頁至首頁
  | 'run.created' // 要處理的 event 之一，從裡面拿到 thread_run_id ＆ thread_id 使用者點擊卡片需要紀錄
  | 'message.created' // 其實幾乎沒有，目前僅預期用來 debug 用 (讓前端觀察其實 API 是有反應的)
  | 'message.in_progress' // 其實幾乎沒有，目前僅預期用來 debug 用 (讓前端觀察其實 API 是有反應的)

export interface ThreadDataType {
  thread_run_id: string
  thread_id: string
}

const AIChatContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Gutters } = useTheme()
  const styles = getStyle()
  const keyword = route.params?.keyword

  const [threadsApiRequest] = threadsApi.usePostThreadsRenewMutation()
  const [getSiteMetaRequest] = api.useLazyGetSiteMetaQuery()

  // 廣告資料
  const { data: advertiseData } = advertiseApi.useGetAdvertiseInfoQuery(
    {
      space: [AdvertiseSpaceEnum.CHATBOT_HISTORY],
    },
    { refetchOnMountOrArgChange: 10 },
  )

  const [messages, setMessages] = useState<AIChatRoomMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const [threadData, setThreadData] = useState<ThreadDataType>({
    thread_run_id: '',
    thread_id: '',
  })

  const eventSourceRef = useRef<EventSource | null>(null)
  const scrollViewRef = useRef<ScrollView>(null)

  const memoizedMessages = useMemo(() => messages, [messages])

  const scrollToBottom = useCallback(() => {
    scrollViewRef.current?.scrollToEnd({ animated: true })
  }, [scrollViewRef])

  const handleGoBack = useCallback(() => {
    threadsApiRequest()

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigate(PagesEnum.Main, { screen: MainPagesEnum.Home })
    }
  }, [navigation, threadsApiRequest])

  // 檢查第一筆 AI 訊息，因為廣告只會出現在第一筆
  const checkFirstAIMessage = useCallback(
    (messageItem: AIChatRoomMessage) => {
      const firstAIMessage = memoizedMessages.find(
        message => message.user.id === MessageType.AI_REFERENCES,
      )
      return firstAIMessage?.id === messageItem?.id
    },
    [memoizedMessages],
  )

  const updateMessages = useCallback((newMessage: AIChatRoomMessage) => {
    setMessages(prevMessages => [...prevMessages, newMessage])
  }, [])

  const handleErrorListener = useCallback(
    (errorCode: number, es: any) => {
      let errorMessage =
        '連線暫時中斷。抱歉，我們的 AI 助理好像去小酌一杯了。很快就回來！'

      if (errorCode === 400) {
        threadsApiRequest()
        errorMessage += '(先前對話進行中)'
      } else if (errorCode === 429) {
      } else if (errorCode === 500) {
      } else if (errorCode === 403) {
        navigate(PagesEnum.Main, { screen: MainPagesEnum.Home })
        getSiteMetaRequest()
      }

      updateMessages({
        id: Math.random().toString(),
        text: errorMessage,
        user: {
          id: MessageType.AI_MESSAGE,
        },
      })
      setIsLoading(false)
      setIsStreaming(false)
      es.close()
    },
    [threadsApiRequest, updateMessages, getSiteMetaRequest],
  )

  const onSend = useCallback(
    (newMessages: AIChatRoomMessage[]) => {
      setIsLoading(true)
      scrollToBottom()

      const question = newMessages[0].text

      if (eventSourceRef.current) {
        eventSourceRef.current.close()
      }

      updateMessages({
        id: Math.random().toString(),
        text: question,
        user: {
          id: MessageType.USER_MESSAGE,
        },
      })
      let fullAnswer = '' // 用於存儲完整的回答

      // TODO: 只測試 beta， gamma 先不變，之後要調回來
      const payload =
        getConfig().ENV === 'beta'
          ? {
              question,
              provider: ThreadRunProvider.GOOGLE,
            }
          : {
              question,
            }

      const es = new EventSource<AIChatRoomEvents>(SERVER_SENT_EVENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        withCredentials: true,
      })

      setIsStreaming(true)

      const listener: EventSourceListener<AIChatRoomEvents> = event => {
        if (event.type === 'open') {
          console.log('connection opened')
          // 初始化訊息
          updateMessages({
            id: Math.random().toString(),
            text: '',
            user: {
              id: MessageType.AI_MESSAGE,
            },
          })
        } else if (event.type === 'message.delta') {
          console.log('message.delta')

          setIsLoading(false)

          const data = JSON.parse(event.data ?? '')
          fullAnswer += data.answer

          // 要將每支酒的 annotations 資訊從 fullAnswer 中移除
          if (data.annotations) {
            const annotations = data.annotations
            annotations.forEach((annotation: string) => {
              fullAnswer = fullAnswer.replace(annotation, '')
            })
          }

          // 更新最後一條 AI 消息的文字
          setMessages(prevMessages => {
            const messagesList = [...prevMessages]
            const lastMessage = messagesList[messagesList.length - 1]
            if (lastMessage.user.id === MessageType.AI_MESSAGE) {
              lastMessage.text = fullAnswer
            }
            return messagesList
          })

          scrollToBottom()
        } else if (event.type === 'run.created') {
          console.log('run.created')
          const data = JSON.parse(event.data ?? '')
          setThreadData(data)
        } else if (event.type === 'message.completed') {
          console.log('message.completed')

          const data = JSON.parse(event.data ?? '')
          const hasReferences = data?.references?.length > 0
          if (hasReferences) {
            updateMessages({
              id: Math.random().toString(),
              text: JSON.stringify(data?.references),
              user: {
                id: MessageType.AI_REFERENCES,
              },
            })
          }
        } else if (event.type === 'done') {
          console.log('EventSource done')

          scrollToBottom()
          setIsLoading(false)
          setIsStreaming(false)
          es.close()
        } else if (event.type === 'error.happened') {
          console.log('EventSource error', event.data)

          const errorOBJ = JSON.parse(event.data ?? '')
          handleErrorListener(errorOBJ.code, es)
        }
      }

      // 如果有新的 type 這邊要記得加
      es.addEventListener('open', listener)
      es.addEventListener('message.delta', listener)
      es.addEventListener('message.completed', listener)
      es.addEventListener('run.created', listener)
      es.addEventListener('done', listener)
      es.addEventListener('error.happened', listener)

      eventSourceRef.current = es

      return () => {
        // 如果有新的 type 這邊要記得加
        es.removeEventListener('open', listener)
        es.removeEventListener('message.delta', listener)
        es.removeEventListener('run.created', listener)
        es.removeEventListener('message.completed', listener)
        es.removeEventListener('done', listener)
        es.removeEventListener('error.happened', listener)
      }
    },
    [updateMessages, handleErrorListener, scrollToBottom],
  )

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close()
      }
    }
  }, [])

  // 如果有 keyword 則直接帶入 keyword onSend
  useEffect(() => {
    if (keyword) {
      const newMessage: AIChatRoomMessage = {
        id: Math.random().toString(),
        text: keyword,
        user: {
          id: MessageType.USER_MESSAGE,
        },
      }
      onSend([newMessage])
      route.params.keyword = ''
    }
  }, [keyword, onSend, route.params])

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'烈酒答人'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[styles.container]}>
          <ScrollView
            ref={scrollViewRef}
            style={styles.chatroomContainer}
            showsVerticalScrollIndicator={false}
          >
            {memoizedMessages.map((message, index) => {
              const isFirstAIMessage = checkFirstAIMessage(message)

              return (
                <CustomMessage
                  key={index}
                  message={message}
                  navigation={navigation}
                  isTyping={isLoading}
                  threadData={threadData}
                  advertiseData={
                    advertiseData?.data?.[
                      AdvertiseSpaceEnum.CHATBOT_HISTORY
                    ]?.[0] || DefaultAdvertiseItem
                  }
                  advertiseSpace={AdvertiseSpaceEnum.CHATBOT_HISTORY}
                  isFirstAIMessage={isFirstAIMessage}
                />
              )
            })}
            {isLoading && (
              <View style={[Gutters.tinyBMargin]}>
                <Image
                  style={[Layout.iconSize24, Gutters.tinyBMargin]}
                  source={Images.ai_chat_avatar}
                  resizeMode="contain"
                />
                <TypingIndicator isTyping={isLoading} />
              </View>
            )}
          </ScrollView>
          <TextInputComponent
            type={TextInputComponentType.CHATROOM}
            placeholder={'詢問 AI...'}
            chatroomOnSubmit={onSend}
            isStreaming={isStreaming}
          />
        </View>
      </FadeInView>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: Platform.OS === 'web' ? 50 : 0,
    },
    container: {
      flex: 1,
      padding: 16,
    },
    chatroomContainer: {
      flex: 1,
      paddingBottom: 32,
    },
  })

export default AIChatContainer
