import { advertiseApi } from '@/Services/advertiseApi'
import userApi from '@/Services/modules/users'

const useUpdateSessions = ({ renew }: { renew: boolean }) => {
  const [getMeDataRequest] = userApi.useLazyGetMeQuery()
  const [postUpdateSession] = advertiseApi.usePostUpdateSessionMutation()

  const updateAdvertiseSession = async () => {
    const getMeResponse = await getMeDataRequest()
    await postUpdateSession({
      renew: renew,
      userId: getMeResponse?.data?.id?.toString() || '',
    })
  }

  const updateAdvertiseLogoutSession = async () => {
    await postUpdateSession({
      renew: true,
      userId: null,
    })
  }

  return {
    updateAdvertiseSession,
    updateAdvertiseLogoutSession,
  }
}

export default useUpdateSessions
