import React, { useEffect } from 'react'
import { View, Image, StyleSheet } from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { DownLoadSign, Header } from '@/Components'

const CampaignSite = ({ navigation, route }: any) => {
  const { Layout, Images } = useTheme()
  const { url } = route.params

  const handleGoBack = () => {
    navigation.goBack()
  }

  // 監聽 iframe 的 postmessage
  useEffect(() => {
    const handlePostMessage = (event: any) => {
      const redirectUrl = event.data?.url
      if (redirectUrl) {
        // @ts-ignore
        window.location.href = redirectUrl
      }
    }
    // @ts-ignore
    window.addEventListener('message', handlePostMessage)
    return () => {
      // @ts-ignore
      window.removeEventListener('message', handlePostMessage)
    }
  }, [])

  return (
    <View style={[Layout.fill]}>
      <View
        style={[Layout.fill, { backgroundColor: Colors.background.surface }]}
      >
        <Header
          title={''}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <iframe src={url} style={styles.iframeContainer} />
      </View>
      <DownLoadSign />
    </View>
  )
}

const styles = StyleSheet.create({
  arrowLeftIcon: { width: 24, height: 24, paddingLeft: 50 },
  iframeContainer: { width: '100%', height: '100%' },
})

export default CampaignSite
