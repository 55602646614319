import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { TextInput } from 'react-native-paper'
import { FadeInView, Header } from '@/Components'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useDispatch } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { SignUpBasketRequestFields } from '@/Services/modules/users/user.type'
import { useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { openApplyInviteCodeWeb, removeEmptyKeys } from '@/Util/global'
import { api } from '@/Services/api'
import { DownLoadSign } from '@/Components'
// import { useTranslation } from 'react-i18next'
import { PagesEnum } from '@/Navigators/Application'

// TODO: 此頁暫時隱藏（避免使用者註冊流程混亂）
const SocialMGMContainer = ({ navigation, route }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()
  const { register } = useSelector((state: RootState) => state.user)
  const { data: siteMetaData } = api.useGetSiteMetaQuery()

  // NOTE:邀請碼選必填機制
  const isMGMCodeRequired = siteMetaData?.features?.invitation || false

  const [
    signUpBasket,
    {
      data: signUpBasketData,
      error: signUpBasketError,
      isError: signUpBasketIsError,
      isSuccess: signUpBasketIsSuccess,
      reset: signUpBasketReset,
    },
  ] = userApi.useSignUpBasketMutation()

  const [inviteCode, setInviteCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  const styles = stylesSheet()

  // 如果沒有帳密資訊就跳回註冊頁面
  useEffect(() => {
    if (!register?.account?.length) {
      navigation.navigate(PagesEnum.EmailRegisterContainer)
    }
  }, [register?.account?.length, navigation])

  // 偵測是否需要開啟 CTA button
  useEffect(() => {
    // NOTE:邀請碼選必填機制
    if (!isMGMCodeRequired) {
      setSubmitButtonDisabled(false)
      return
    }
    if (inviteCode?.length) {
      setSubmitButtonDisabled(false)
      return
    }
    setSubmitButtonDisabled(true)
  }, [inviteCode?.length, isMGMCodeRequired])

  useEffect(() => {
    if (signUpBasketIsError) {
      const errorRes = signUpBasketError as any
      const message = errorRes?.data?.errors?.[0]?.message

      if (message === 'InvalidInviteCode') {
        setErrorMessage('邀請碼無效')
      }

      if (message === 'InviteCodeMaxUsagesExceed') {
        setErrorMessage('邀請碼兌換已滿額')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpBasketError, signUpBasketIsError])

  // MGM 認證成功流程
  useEffect(() => {
    if (signUpBasketIsSuccess) {
      // 如果 social 有信箱資料就直接跳到基本資料頁面不用去驗證信箱
      if (register?.socialHasAccount) {
        navigation.navigate(PagesEnum.SocialBasicInfoContainer, {
          basket: signUpBasketData?.data?.basket || '',
        })
        signUpBasketReset()
        return
      }
      navigation.navigate(PagesEnum.SocialOTPContainer, {
        basket: signUpBasketData?.data?.basket || '',
      })
      signUpBasketReset()
    }
  }, [
    navigation,
    signUpBasketIsSuccess,
    signUpBasketData,
    signUpBasketReset,
    register,
    dispatch,
  ])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const handleSubmit = () => {
    // NOTE:邀請碼選必填機制
    if (!isMGMCodeRequired) {
      // 如果 social 有信箱資料就直接跳到基本資料頁面不用去驗證信箱
      if (register?.socialHasAccount) {
        navigation.navigate(PagesEnum.SocialBasicInfoContainer, {
          basket: route?.params?.basket || '',
        })
        signUpBasketReset()
        return
      }
      navigation.navigate(PagesEnum.SocialOTPContainer, {
        basket: route?.params?.basket || '',
      })
      return
    }

    const payload: Partial<SignUpBasketRequestFields> = {
      inviteCode: inviteCode,
    }

    signUpBasket({
      oldBasket: route?.params?.basket,
      fields: {
        ...removeEmptyKeys(payload),
      },
    })
  }

  const loginButtonBg = submitButtonDisabled ? '#D9D9D9' : Colors.primary

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <ScrollView style={[Layout.fill]}>
        <Header
          title={'註冊 (1/4)'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View style={[Gutters.smallTPadding]}>
          {/* 輸入 MGM 欄位 */}
          <FadeInView duration={550} style={Gutters.regularHPadding}>
            <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
              <Text
                style={[
                  Fonts.size20,
                  Fonts.weight700,
                  Gutters.tinyBMargin,
                  { color: Colors.fontText.light.primary1 },
                ]}
              >
                尚未擁有烈酒探索家帳號
              </Text>
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight400,
                  Gutters.tinyBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {isMGMCodeRequired
                  ? '須獲得邀請碼才可註冊使用'
                  : '輸入邀請碼 (選填)'}
              </Text>
              <TextInput
                label="請輸入邀請碼"
                style={[
                  styles.textInput,
                  errorMessage ? Gutters.tinyBMargin : Gutters.regularBMargin,
                  { color: Colors.white },
                ]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage}
                onChangeText={value => {
                  setInviteCode(value)
                  if (errorMessage) {
                    setErrorMessage('')
                  }
                }}
              />
              {!!errorMessage && (
                <Text
                  style={[
                    Gutters.tinyBMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage}
                </Text>
              )}
            </View>
          </FadeInView>
          {/* 下一步按鈕 */}
          <FadeInView duration={550}>
            <View style={[Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.buttonContainer,
                  {
                    backgroundColor: loginButtonBg,
                  },
                ]}
                // NOTE:邀請碼選必填機制
                disabled={submitButtonDisabled}
                onPress={handleSubmit}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.bold,
                    // NOTE:邀請碼選必填機制
                    {
                      color: submitButtonDisabled
                        ? Colors.gray
                        : Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  下一步
                </Text>
              </TouchableOpacity>
            </View>
          </FadeInView>
        </View>
        {/* 註冊帳號、隱私權 */}
        <View style={styles.signInContainer}>
          <View style={[Layout.rowCenter, Gutters.smallBMargin]}>
            {isMGMCodeRequired && (
              <TouchableOpacity onPress={() => openApplyInviteCodeWeb()}>
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size16,
                    {
                      color: Colors.fontText.light.primary2,
                      textDecorationLine: 'underline',
                      //@ts-ignore
                      textUnderlineOffset: 4,
                    },
                  ]}
                >
                  我沒有邀請碼
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    buttonContainer: {
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
      paddingVertical: 9,
    },
    signInContainer: {
      marginTop: 40,
      alignItems: 'center',
      width: '100%',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
  })

export default SocialMGMContainer
