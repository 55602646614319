import React, { useCallback, useEffect, useState } from 'react'
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

import { MessageType } from './customMessage'
import { AIChatRoomMessage } from '../types'

const DELAY_DISABLED_SUBMIT = 500

export enum TextInputComponentType {
  CHATROOM = 'chatroom',
  DEFAULT = 'default',
}

interface TextInputComponentProps {
  type: TextInputComponentType
  placeholder: string
  onSubmit?: (text: string) => void
  chatroomOnSubmit?: (messages: AIChatRoomMessage[]) => void
  setInputFocus?: React.Dispatch<React.SetStateAction<boolean>>
  isStreaming?: boolean
}

const TextInputComponent = ({
  type,
  onSubmit,
  chatroomOnSubmit,
  setInputFocus,
  placeholder,
  isStreaming,
}: TextInputComponentProps) => {
  const { Layout, Images } = useTheme()

  const [inputText, setInputText] = useState('')
  const [textInputHeight, setTextInputHeight] = useState<number>(0)

  const styles = getStyle({ hasSubmitBtn: !!inputText?.length })

  const handleSubmit = useCallback(() => {
    if (type === TextInputComponentType.CHATROOM && chatroomOnSubmit) {
      const newMessage: AIChatRoomMessage = {
        id: Math.random().toString(),
        text: inputText,
        user: {
          id: MessageType.USER_MESSAGE,
        },
      }
      chatroomOnSubmit([newMessage])
    } else {
      onSubmit?.(inputText)
    }
    setInputText('')
  }, [inputText, onSubmit, chatroomOnSubmit, type])

  useEffect(() => {
    setInputText(inputText)
  }, [inputText])

  return (
    <View style={styles.inputContainer}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : 0}
        style={[styles.inputItem]}
      >
        <TextInput
          style={[
            Layout.fullWidth,
            {
              color: Colors.fontText.light.primary2,
              height: Math.max(0, textInputHeight),
              minHeight: 20,
              maxHeight: 200,
            },
          ]}
          onFocus={() => setInputFocus?.(true)}
          onBlur={() => {
            setTimeout(() => {
              setInputFocus?.(false)
            }, DELAY_DISABLED_SUBMIT)
          }}
          value={inputText}
          placeholder={placeholder}
          placeholderTextColor={Colors.fontText.light.primary4}
          onSubmitEditing={handleSubmit}
          onChangeText={text => setInputText(text)}
          multiline
          numberOfLines={Platform.OS === 'ios' ? 5 : 15}
          disableFullscreenUI={true}
          // 紀錄輸入框的高度
          onContentSizeChange={event =>
            setTextInputHeight(event.nativeEvent.contentSize.height)
          }
        />
        {!!inputText?.length && !isStreaming && (
          <View style={styles.submitBtn}>
            <TouchableOpacity onPress={handleSubmit}>
              <Image
                source={Images.review_note_send}
                style={[Layout.iconSize24]}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        )}
      </KeyboardAvoidingView>
    </View>
  )
}

const getStyle = ({ hasSubmitBtn }: { hasSubmitBtn: boolean }) => {
  return StyleSheet.create({
    inputContainer: {
      backgroundColor: Colors.background.default,
      paddingBottom: 16,
    },
    inputItem: {
      flexDirection: hasSubmitBtn ? 'row' : 'column',
      justifyContent: hasSubmitBtn ? 'space-between' : 'flex-start',
      alignItems: hasSubmitBtn ? 'center' : 'flex-start',
      borderRadius: 10,
      paddingVertical: 16,
      paddingLeft: 16,
      paddingRight: 48,
      borderColor: Colors.background.top,
      borderWidth: 1,
      color: Colors.fontText.light.primary2,
      ...Platform.select({
        web: {
          minHeight: 56,
        },
        ios: {
          minHeight: 64,
          paddingTop: 20,
        },
        android: {
          minHeight: 64,
        },
      }),
    },
    submitBtn: {
      marginRight: -25,
      marginBottom: -10,
    },
  })
}

TextInputComponent.defaultProps = {
  onSubmit: () => {},
  chatroomOnSubmit: () => {},
  setInputFocus: () => {},
  isStreaming: false,
}

export default TextInputComponent
