import React, { useEffect, useState } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { capitalizedSentence } from '@/Util/global'

import { PairingDataItem } from '../hooks/useGetPairingsData'
import { CountryDataItem } from '../hooks/useGetCountriesData'
import { BrandDataItem } from '../hooks/useGetBrandsData'
import { FlavorDataItem } from '../hooks/useGetFlavorData'
import { RegionDataItem } from '../hooks/useGetRegionsData'
import { CategoryDataItem } from '../hooks/useGetCategoriesData'

interface SectionContainerProps {
  title: string
  type: string
  itemsData:
    | PairingDataItem[]
    | CountryDataItem[]
    | BrandDataItem[]
    | FlavorDataItem[]
    | CategoryDataItem[]
    | RegionDataItem[]
  canOpen: boolean
  selectedItems?: string[]
  showMoreButton?: boolean
  onMorePress?: () => void
  needEnName?: boolean
}

const SectionContainer = ({
  title,
  type,
  itemsData,
  selectedItems,
  canOpen,
  onMorePress,
  showMoreButton = true,
  needEnName = false,
}: SectionContainerProps) => {
  const navigation = useNavigation()
  const route = useRoute()
  const { Fonts, Images, Layout, Gutters } = useTheme()
  const styles = getStyle()

  const defaultOpen = canOpen ? false : true
  const [open, setOpen] = useState(defaultOpen)

  const handleSelect = (itemKey: string) => {
    if (!selectedItems) {
      return
    }

    const newSelectedItems = selectedItems?.includes(itemKey)
      ? selectedItems?.filter(key => key !== itemKey)
      : typeof selectedItems === 'string' && selectedItems
      ? // @ts-ignore
        [...selectedItems.split(','), itemKey]
      : [...selectedItems, itemKey]

    const filter: { [key: string]: string[] | undefined } = {
      ...route.params,
      [type]: newSelectedItems.length > 0 ? newSelectedItems : undefined,
    }
    // @ts-ignore
    navigation.setParams(filter)
  }

  // 有值的話自動先打開
  useEffect(() => {
    if (selectedItems?.length) {
      setOpen(true)
    }
  }, [selectedItems, type])

  return (
    <View style={[styles.section]}>
      <TouchableOpacity
        style={[styles.sectionTitle]}
        onPress={() => setOpen(!open)}
      >
        <Text
          style={[
            Fonts.weight500,
            Fonts.size16,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
        {canOpen && (
          <Image
            source={Images.arrowRight}
            style={[
              Layout.iconSize24,
              { transform: [{ rotate: open ? '-90deg' : '90deg' }] },
            ]}
          />
        )}
      </TouchableOpacity>
      {open && (
        <>
          <View style={[styles.sectionContent]}>
            {itemsData.map((item, index) => {
              const isSelected = selectedItems?.includes(item.i18nKey) || false
              // 如果 i18n 沒有對起來的話，就不顯示
              const isHideItem = item.enName === '' && item.zhName === ''
              return (
                <TouchableOpacity
                  style={[
                    styles.sectionContentItem,
                    isSelected && styles.sectionContentItemSelected,
                    isHideItem && styles.sectionContentItemHide,
                  ]}
                  key={index}
                  onPress={() => handleSelect(item.i18nKey)}
                >
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size14,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {capitalizedSentence(item.zhName)}
                  </Text>
                  {needEnName && (
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Gutters.miniBMargin,
                        {
                          color: isSelected
                            ? Colors.fontText.dark.primary2
                            : Colors.fontText.light.primary2,
                        },
                      ]}
                    >
                      {capitalizedSentence(item.enName)}
                    </Text>
                  )}
                  <Text
                    style={[
                      Fonts.weight300,
                      Fonts.size14,
                      Gutters.tinyTMargin,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    ({item.count})
                  </Text>
                </TouchableOpacity>
              )
            })}
          </View>
          {showMoreButton && (
            <TouchableOpacity style={[styles.moreButton]} onPress={onMorePress}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primary },
                ]}
              >
                查看全部
              </Text>
            </TouchableOpacity>
          )}
        </>
      )}
    </View>
  )
}

SectionContainer.defaultProps = {
  selectedItems: [],
  showMoreButton: true,
  onMorePress: () => {},
  needEnName: false,
}

const getStyle = () =>
  StyleSheet.create({
    section: {
      marginBottom: 16,
    },
    sectionTitle: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    sectionContent: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
      marginBottom: 16,
    },
    sectionContentItem: {
      boxSizing: 'border-box',
      width: '48.5%',
      borderWidth: 1,
      borderColor: Colors.background.top,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 8,
    },
    sectionContentItemSelected: {
      backgroundColor: Colors.primary,
      borderColor: 'none',
    },
    sectionContentItemHide: {
      display: 'none',
    },
    moreButton: {
      marginHorizontal: 'auto',
      marginVertical: 8,
    },
  })

export default SectionContainer
