import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native'
import { useTheme, useUpdateSessions } from '@/Hooks'
import { FadeInView, Header } from '@/Components'
import { getConfig } from '@/Util/global'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useIsFocused } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { globalActions } from '@/Store/Global'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { SignUpBasketRequestFields } from '@/Services/modules/users/user.type'
import { userActions } from '@/Store/User'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'
// import AppleLogin from 'react-apple-login'
// import { useTranslation } from 'react-i18next'

enum SOCIAL_LOGIN_TYPE {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

const LoginContainer = ({ navigation, route }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const { updateAdvertiseSession } = useUpdateSessions({ renew: false })
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()
  const isFocused = useIsFocused()
  const headerHeight = 64

  const [
    socialLoginRequest,
    {
      data: socialLoginData,
      error: socialLoginError,
      isError: socialLoginIsError,
      isLoading: socialLoginIsLoading,
      isSuccess: socialLoginIsSuccess,
      reset: socialLoginReset,
    },
  ] = userApi.useSignInWithSocialMutation()

  const [
    signUpBasket,
    {
      data: signUpBasketData,
      isSuccess: signUpBasketIsSuccess,
      reset: signUpBasketReset,
    },
  ] = userApi.useSignUpBasketMutation()

  const [socialData, setSocialData] = useState({
    provider: '',
    token: '',
  })
  const [socialLoginButtonShowNum, setSocialLoginButtonShowNum] =
    useState<number>(0)

  const styles = stylesSheet(headerHeight)

  useEffect(() => {
    if (isFocused) {
      dispatch(
        userActions.setUserRegisterData({
          socialHasAccount: false,
        }),
      )
    }
  }, [dispatch, isFocused])

  useEffect(() => {
    const storeData = async (value: string) => {
      try {
        await AsyncStorage.setItem('auth-token', value)
      } catch (e) {
        console.error(e)
      }
    }
    if (socialLoginIsSuccess) {
      storeData(socialLoginData?.data?.token || '')
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '成功登入，歡迎回來！',
          type: 'default',
          showIcon: false,
        }),
      )
      updateAdvertiseSession()
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.navigate(PagesEnum.Main, { screen: MainPagesEnum.Profile })
      }
    }
  }, [
    navigation,
    dispatch,
    socialLoginIsSuccess,
    socialLoginData?.data?.token,
    updateAdvertiseSession,
  ])

  // social 登入失敗流程 ()
  useEffect(() => {
    if (socialLoginIsError) {
      const errorData = socialLoginError as any
      const { message } = errorData?.data?.errors?.[0]

      // 如果帳號不存在，走註冊流程，記得先帶 token 打 basket
      if (message === 'AccountNotExists') {
        const payload: Partial<SignUpBasketRequestFields> = {
          social: {
            provider: socialData.provider,
            token: socialData.token,
          },
        }

        signUpBasket({
          fields: {
            ...payload,
          },
        })
        socialLoginReset()
      }
    }
  }, [
    signUpBasket,
    socialData.provider,
    socialData.token,
    socialLoginError,
    socialLoginIsError,
    socialLoginReset,
  ])

  // 新增完 basket 流程
  useEffect(() => {
    if (signUpBasketIsSuccess) {
      navigation.navigate(PagesEnum.SocialOTPContainer, {
        basket: signUpBasketData?.data?.basket || '',
      })
      dispatch(
        userActions.setUserRegisterData({
          account: signUpBasketData?.data?.basket || '',
          socialHasAccount:
            !!signUpBasketData?.data?.account && !!socialData?.provider?.length,
          socialProvider: socialData?.provider,
        }),
      )
      signUpBasketReset()
    }
  }, [
    dispatch,
    navigation,
    signUpBasketData?.data,
    signUpBasketIsSuccess,
    signUpBasketReset,
    socialData,
  ])

  const handleFBSignIn = (res: any) => {
    const { accessToken, name } = res
    socialLoginRequest({
      name,
      token: accessToken,
      provider: SOCIAL_LOGIN_TYPE.FACEBOOK,
    })
    setSocialData({
      provider: SOCIAL_LOGIN_TYPE.FACEBOOK,
      token: accessToken,
    })
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      // @ts-ignore
      const urlParams = new URLSearchParams(window.location.href)
      const idToken = urlParams.get('id_token')

      if (idToken) {
        socialLoginRequest({
          name: '',
          token: idToken,
          provider: SOCIAL_LOGIN_TYPE.APPLE,
        })
        setSocialData({
          provider: SOCIAL_LOGIN_TYPE.APPLE,
          token: idToken,
        })
        navigation.setParams({ id_token: undefined })
      }
    }
  }, [route, socialLoginRequest, navigation])

  const handleClickLogin = () => {
    navigation.navigate(PagesEnum.EmailLoginContainer)
    setSocialLoginButtonShowNum(0)
  }

  const handleGoBack = () => {
    navigation.navigate(PagesEnum.Main, { screen: MainPagesEnum.Home })
    setSocialLoginButtonShowNum(0)
  }

  const handleGoPrivacy = () => {
    navigation.navigate(PagesEnum.Privacy)
    setSocialLoginButtonShowNum(0)
  }

  const handleCredentialResponse = (response: any) => {
    socialLoginRequest({
      name: '',
      token: response?.credential,
      provider: SOCIAL_LOGIN_TYPE.GOOGLE,
    })
    setSocialData({
      provider: SOCIAL_LOGIN_TYPE.GOOGLE,
      token: response?.credential,
    })
  }

  // 是否顯示社群登入按鈕（計算按十下是因為後門開關）
  const handleRenderSocialLoginButton = (socialType: SOCIAL_LOGIN_TYPE) => {
    if (socialType === SOCIAL_LOGIN_TYPE.APPLE) {
      return (
        getConfig()?.OPEN_SOCIAL_LOGIN_BUTTON_APPLE ||
        socialLoginButtonShowNum >= 10
      )
    }
    if (socialType === SOCIAL_LOGIN_TYPE.GOOGLE) {
      return (
        getConfig()?.OPEN_SOCIAL_LOGIN_BUTTON_GOOGLE ||
        socialLoginButtonShowNum >= 10
      )
    }
    if (socialType === SOCIAL_LOGIN_TYPE.FACEBOOK) {
      return (
        getConfig()?.OPEN_SOCIAL_LOGIN_BUTTON_FACEBOOK ||
        socialLoginButtonShowNum >= 10
      )
    }
    return false
  }

  const initializeGSI = () => {
    if (Platform.OS === 'web') {
      // @ts-ignore
      if (!window.google) {
        setTimeout(() => {
          initializeGSI()
        }, 500)
      }
      // @ts-ignore
      if (window.google) {
        // @ts-ignore
        window.google.accounts.id.initialize({
          client_id: getConfig()?.GOOGLE_WEB_ID,
          callback: handleCredentialResponse,
        })
        // @ts-ignore
        window.google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById('googleButton'),
          {
            theme: 'outline',
            size: 'large',
            width: Dimensions.get('window').width - 100,
            // type: 'icon',
            logo_alignment: 'left',
            scope: 'https://www.googleapis.com/auth/userinfo.email',
          },
        )
      }
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      // @ts-ignore
      const script: any = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.onload = initializeGSI()
      script.async = true
      // @ts-ignore
      const body = document.querySelector('body')
      if (body) {
        body.appendChild(script)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLoading = () => {
    if (socialLoginIsLoading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={Colors.white} />
        </View>
      )
    }
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.primary }]}>
      <ScrollView style={[Layout.fill]}>
        {renderLoading()}
        <Header
          leftIcon={
            <Image
              style={[Layout.iconSize24, styles.paddingL50]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          backgroundColor="transparent"
        />
        {/* 註冊帳號、隱私權 */}
        <FadeInView style={styles.bottomContainer}>
          <View />
          <TouchableWithoutFeedback
            style={[Layout.colCenter]}
            onPress={() => setSocialLoginButtonShowNum(prev => prev + 1)}
          >
            <Image
              source={Images.logoSlogan}
              style={[Gutters.smallBMargin, styles.logoIcon]}
              resizeMode="contain"
            />
          </TouchableWithoutFeedback>
          <View style={[Layout.fullWidth]}>
            {/* 社群登入 */}
            {/* Facebook */}
            {handleRenderSocialLoginButton(SOCIAL_LOGIN_TYPE.FACEBOOK) && (
              <FacebookLogin
                appId={getConfig().FB_APPID}
                scope="public_profile,email"
                callback={e => handleFBSignIn(e)}
                disableMobileRedirect={true}
                redirectUri={`${getConfig().DOMAIN}/LoginMainContainer`}
                render={renderProps => (
                  <TouchableOpacity
                    style={[
                      Layout.fullWidth,
                      Layout.rowCenter,
                      Gutters.smallBMargin,
                      styles.socialLoginButtonContainer,
                    ]}
                    onPress={renderProps.onClick}
                  >
                    <Image
                      source={Images.login_main_FB}
                      resizeMode="contain"
                      style={styles.socialLoginIcon}
                    />
                    <Text
                      style={[
                        Fonts.textCenter,
                        Fonts.weight500,
                        Fonts.size16,
                        { color: Colors.fontText.dark.primary2 },
                      ]}
                    >
                      以 Facebook 帳號繼續
                    </Text>
                    <View />
                  </TouchableOpacity>
                )}
              />
            )}
            {/* Google */}
            {handleRenderSocialLoginButton(SOCIAL_LOGIN_TYPE.GOOGLE) && (
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.socialLoginButtonContainer,
                ]}
              >
                <Image
                  source={Images.login_main_Google}
                  resizeMode="contain"
                  style={styles.socialLoginIcon}
                />
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.dark.primary2 },
                  ]}
                >
                  以 Google 帳號繼續
                </Text>
                <View />
                <View
                  nativeID="googleButton"
                  style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
                />
              </TouchableOpacity>
            )}
            {/* Apple */}
            {/* <AppleLogin
              clientId={getConfig()?.APPLE_WEB_ID}
              redirectURI={getConfig()?.APPLE_WEB_REDIRECT_URL}
              responseType="code id_token"
              responseMode="fragment"
              render={({ onClick }) => {
                return (
                  <TouchableOpacity
                    style={[
                      Layout.fullWidth,
                      Layout.rowCenter,
                      Gutters.smallBMargin,
                      styles.socialLoginButtonContainer,
                    ]}
                    onPress={() => {
                      onClick()
                    }}
                  >
                    <Image
                      source={Images.login_main_Apple}
                      resizeMode="contain"
                      style={styles.socialLoginIcon}
                    />
                    <Text
                      style={[
                        Fonts.textCenter,
                        Fonts.weight500,
                        Fonts.size16,
                        { color: Colors.fontText.dark.primary2 },
                      ]}
                    >
                      以 Apple 帳號繼續
                    </Text>
                    <View />
                  </TouchableOpacity>
                )
              }}
            /> */}
            {/* 信箱 */}
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.smallBMargin,
                styles.socialLoginButtonContainer,
              ]}
              onPress={handleClickLogin}
            >
              <Image
                source={Images.login_main_Mail}
                resizeMode="contain"
                style={[styles.socialLoginIcon]}
              />
              <Text
                style={[
                  Fonts.textCenter,
                  Fonts.weight500,
                  Fonts.size16,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                以電子郵件繼續
              </Text>
              <View />
            </TouchableOpacity>
            <View style={[Layout.rowCenter]}>
              <Text
                style={[
                  Fonts.textCenter,
                  Fonts.weight400,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                繼續即表示您同意
              </Text>
              <TouchableOpacity onPress={handleGoPrivacy}>
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.weight400,
                    Fonts.size14,
                    Gutters.tinyLMargin,
                    styles.underline,
                    {
                      color: Colors.primaryText,
                    },
                  ]}
                >
                  使用條款和隱私政策
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = (headerHeight: number) =>
  StyleSheet.create({
    paddingL50: {
      paddingLeft: 50,
    },
    socialLoginIcon: {
      width: 40,
      height: 40,
      position: 'absolute',
      left: 5,
      top: 3,
    },
    socialLoginButtonContainer: {
      borderRadius: 8,
      backgroundColor: Colors.white,
      paddingVertical: 9,
      height: 48,
      borderWidth: 1,
      borderColor: Colors.fontText.dark.primary2,
    },
    bottomContainer: {
      height: Dimensions.get('window').height - headerHeight,
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
      paddingHorizontal: 24,
      paddingBottom: 24,
    },
    loadingContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999,
      width: Dimensions.get('window').width,
      maxWidth: 428,
      height: Dimensions.get('window').height,
      justifyContent: 'center',
      alignItems: 'center',
    },
    underline: {
      textDecorationLine: 'underline',
      textUnderlineOffset: 4,
    },
    logoIcon: {
      width: 286,
      height: 180,
    },
  })

export default LoginContainer
