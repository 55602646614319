import React, { useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { Portal, RadioButton } from 'react-native-paper'

import { useTheme } from '@/Hooks'
import { SpiritSort } from '@/Services/modules/spirits/spirits.type'
import { Colors } from '@/Theme/Variables'

export const SORT_BTN_ARR = [
  {
    label: '綜合排名',
    value: SpiritSort.RANK_SCORE,
  },
  {
    label: '最高價格',
    value: SpiritSort.PRICE_HIGH_TO_LOW,
  },
  {
    label: '最低價格',
    value: SpiritSort.PRICE_LOW_TO_HIGH,
  },
]

interface SortModalProps {
  visible: boolean
  hideModal: () => void
  onSort: (value: string) => void
  sortValue: {
    label: string
    value: string
  }
  setSortValue: (value: { label: string; value: string }) => void
  handleSortSubmit: (value: string) => void
}

const SortModal = ({
  hideModal,
  onSort,
  sortValue,
  setSortValue,
  handleSortSubmit,
}: SortModalProps) => {
  const styles = getStyle()
  const { Layout, Fonts, Gutters } = useTheme()
  const [value, setValue] = useState<{
    label: string
    value: string
  }>(sortValue)

  const handleSubmit = () => {
    onSort(value.value)
    hideModal()
    handleSortSubmit(value.value)
  }

  return (
    <Portal>
      <View style={styles.filter}>
        <TouchableOpacity
          style={[Layout.fullHeight]}
          onPress={() => {
            hideModal()
          }}
        />
      </View>
      <View style={[Gutters.regularHPadding, styles.modalContent]}>
        <View style={[{ marginBottom: 100 }]}>
          {SORT_BTN_ARR.map((item, index) => (
            <TouchableOpacity
              key={index}
              style={styles.sortItem}
              onPress={() => {
                setValue(item)
                setSortValue(item)
              }}
            >
              <RadioButton
                value={item.value}
                status={value.value === item.value ? 'checked' : 'unchecked'}
                onPress={() => {
                  setValue(item)
                  setSortValue(item)
                }}
                color={Colors.primary}
              />
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {item.label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <TouchableOpacity
          style={styles.sortSubmitButton}
          onPress={handleSubmit}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            儲存
          </Text>
        </TouchableOpacity>
      </View>
    </Portal>
  )
}

const getStyle = () =>
  StyleSheet.create({
    filter: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
    },
    modalContent: {
      width: '100%',
      maxWidth: 428,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#1E1E1E',
      opacity: 1,
      borderTopLeftRadius: 10,
      paddingBottom: 24,
    },
    sortItem: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: Colors.background.top,
      paddingRight: 16,
      paddingVertical: 16,
      alignItems: 'center',
    },
    sortSubmitButton: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.primary,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 'auto',
    },
  })

export default SortModal
