import React, { useCallback, useEffect, useState } from 'react'
import { View, Image, Text, StyleSheet, ScrollView } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import StarRating, { StarIconProps } from 'react-native-star-rating-widget'
import Svg, { SvgProps, Path } from 'react-native-svg'
// import { useTranslation } from 'react-i18next'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { spiritsApi } from '@/Services/modules/spirits'
import { FadeInView, Header } from '@/Components'
import { spiritActions } from '@/Store/Spirit'
import { useIsFocused } from '@react-navigation/native'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

import { RatingDescription, RatingCupIcons } from './config'

const StarEmpty = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M16 1.61804L19.1167 11.2102L19.229 11.5557H19.5922H29.6781L21.5184 17.484L21.2246 17.6976L21.3368 18.0431L24.4535 27.6353L16.2939 21.7069L16 21.4934L15.7061 21.7069L7.54649 27.6353L10.6632 18.0431L10.7754 17.6976L10.4816 17.484L2.32194 11.5557H12.4078H12.771L12.8833 11.2102L16 1.61804Z"
      stroke="#D9D9D9"
    />
  </Svg>
)

const StarFull = (props: SvgProps) => (
  <Svg {...props}>
    <Path d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z" />
  </Svg>
)

// 這個是比較大且可以操作的 rating icon
const RatingStarIcon = ({ color, size, type }: StarIconProps) => {
  if (type === 'empty') {
    return (
      <StarEmpty fill={Colors.background.default} width={size} height={size} />
    )
  }

  return <StarFull fill={color} width={size} height={size} />
}

const RatingAndCommentContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  // const { t } = useTranslation()
  const dispatch = useDispatch()
  const focused = useIsFocused()
  const { id, mySpiritId, page, searchKeyword, titleUrlSlug } = route?.params
  const { spirit: spiritData } = useSelector((state: RootState) => state.spirit)

  // 取得該支酒的個人資訊，包括是否有收藏、個人評論
  const { data: personalSpiritData } = spiritsApi.useGetSpiritPersonalInfoQuery(
    {
      id: route.params?.id,
    },
  )

  const [hideHeader, setHideHeader] = useState(false)
  const [ratingDescription, setRatingDescription] = useState<string>(
    RatingDescription[0].content,
  )
  const [cupImage, setCupImage] = useState<string>(RatingCupIcons[0].icon)

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  // 放入 rating
  useEffect(() => {
    const mySpiritRating = personalSpiritData?.data?.myNotes[0]?.rating
    if (mySpiritRating && focused) {
      dispatch(
        spiritActions.setSpiritData({
          rating: spiritData?.rating || mySpiritRating,
        }),
      )
    }
  }, [dispatch, personalSpiritData?.data, spiritData?.rating, focused])

  // 選擇 rating 描述
  useEffect(() => {
    const desc = RatingDescription.find(
      (item: any) => item.rating === spiritData?.rating,
    )
    const cupIcon = RatingCupIcons.find(
      (item: any) => item.rating === spiritData?.rating,
    )
    setRatingDescription(desc?.content || '')
    setCupImage(cupIcon?.icon || RatingCupIcons[0].icon)
  }, [spiritData?.rating])

  const handleHideHeader = (event: any) => {
    const { y } = event.nativeEvent.contentOffset
    if (y > 50 && !hideHeader) {
      setHideHeader(true)
    }
    if (y < 50 && hideHeader) {
      setHideHeader(false)
    }
  }

  const handleRedirectToDetail = (rating: number) => {
    const routeParams: any = {
      id,
      mySpiritId,
      titleUrlSlug,
      page: page !== undefined ? page : '',
      searchKeyword: searchKeyword !== undefined ? searchKeyword : '',
    }
    Object.keys(routeParams).forEach(key => {
      if (routeParams[key] === undefined || routeParams[key] === '') {
        delete routeParams[key]
      }
    })

    dispatch(
      spiritActions.setSpiritData({
        rating,
      }),
    )

    // workaround 直接跳轉，rating 元件會再自動觸發 onChange value = 5
    setTimeout(() => {
      navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
    }, 100)
  }

  const renderContentWithoutRating = () => {
    return (
      <View style={[Layout.colCenter]}>
        <Text style={[Fonts.size14, { color: Colors.gray }]}>你知道嗎？</Text>
        <Text style={[Fonts.size14, { color: Colors.gray }]}>
          整體平均評分為 3.5，所以 4 以上是評價很好的酒！
        </Text>
      </View>
    )
  }

  return (
    <View style={[Layout.fill]}>
      <Header
        leftIcon={
          <Image
            style={[styles.closeIcon]}
            source={Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
        styles={styles.headerContainer}
        backgroundColor="transparent"
      />
      <View style={styles.backgroundBlur} />
      <ScrollView
        style={[Layout.fill, styles.container]}
        onScroll={handleHideHeader}
        contentContainerStyle={[Layout.fill]}
      >
        <FadeInView duration={250}>
          <View style={[Layout.alignItemsCenter]}>
            <Image
              style={styles.mainImage}
              // @ts-ignore
              source={Images[cupImage]}
              resizeMode="cover"
            />
          </View>
        </FadeInView>
        <FadeInView duration={250} style={styles.marginB150}>
          <View style={[Layout.center, Gutters.smallBMargin]}>
            <Text style={[Fonts.bold, Fonts.size20, { color: Colors.white }]}>
              {ratingDescription}
            </Text>
            <StarRating
              rating={spiritData?.rating || 0}
              starSize={30}
              color="#EECD96"
              emptyColor="#D9D9D9"
              enableHalfStar={false}
              StarIconComponent={RatingStarIcon}
              onChange={value => {
                handleRedirectToDetail(value)
              }}
              style={styles.starRatingContainer}
              starStyle={styles.starStyle}
              animationConfig={{
                scale: 1,
              }}
            />
          </View>
          {!spiritData?.rating && renderContentWithoutRating()}
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    marginB150: {
      marginBottom: 150,
    },
    backgroundBlur: {
      backdropFilter: 'blur(20px)',
      flex: 1,
    },
    container: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: Colors.background.default,
      Offset: '0px, -4px rgba(0, 0, 0, 0.5)',
    },
    mainImage: {
      width: 70,
      height: 80,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 60,
      marginBottom: 16,
    },
    headerContainer: {
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 10,
      left: 0,
      zIndex: 99,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      borderTopColor: Colors.darkText,
      borderTopWidth: 1,
      backgroundColor: Colors.background.default,
    },
    starStyle: {
      marginRight: 3,
    },
    starRatingContainer: {
      marginLeft: -8,
      marginTop: 8,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default RatingAndCommentContainer
