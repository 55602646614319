import React, { useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import { AdvertiseItem, AdvertiseSpaceEnum } from '@/Services/advertiseApi'
import { AdvertiseComponent } from '@/Components'
import { Colors } from '@/Theme/Variables'

interface SliderCardProps {
  data: AdvertiseItem[]
  space: AdvertiseSpaceEnum
}

const IMAGE_SIZE = {
  width: 385,
  height: 100,
}

const AdvertiseCarousel = ({ data, space }: SliderCardProps) => {
  const [sliderIndex, setSliderIndex] = useState(0)

  const containerRef = useRef<View>(null)
  const sliderItemWidth =
    // @ts-ignore
    containerRef.current?.getBoundingClientRect().width - 48
  const sliderNumber = data?.length

  const styles = style(sliderItemWidth)

  return (
    <>
      <View style={styles.carouselContainer}>
        <Carousel
          centerSlidePercentage={100}
          showArrows={true}
          showIndicators={false}
          swipeable={true}
          emulateTouch={true}
          selectedItem={sliderIndex}
          onChange={index => setSliderIndex(index)}
          width={sliderItemWidth}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
        >
          {data?.map((item: AdvertiseItem) => {
            return (
              <View style={[styles.carouselItem]} key={item.id}>
                <AdvertiseComponent
                  data={item}
                  space={space}
                  customImageStyle={{ borderRadius: 8 }}
                  customImageContainerStyle={{ borderRadius: 8 }}
                />
              </View>
            )
          })}
        </Carousel>
      </View>
      <View ref={containerRef} style={[styles.sliderDotsContainer]}>
        {new Array(sliderNumber).fill(0).map((_: number, index: number) => {
          const isActive = index === sliderIndex
          return (
            <View
              style={[isActive ? styles.sliderDotActive : styles.sliderDot]}
            />
          )
        })}
      </View>
    </>
  )
}

const style = (sliderItemWidth: number) =>
  StyleSheet.create({
    carouselContainer: {
      width: '100%',
      height: IMAGE_SIZE.height,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 24,
      marginTop: 8,
    },
    carouselItem: {
      width: sliderItemWidth,
      height: IMAGE_SIZE.height,
    },
    sliderDotsContainer: {
      width: '100%',
      height: 40,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
    },
    sliderDot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: '#545454',
    },
    sliderDotActive: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: Colors.primary,
    },
  })

export default AdvertiseCarousel
