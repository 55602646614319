import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '@/Hooks'
import { RootState } from '@/Store'
import { advertiseActions } from '@/Store/Advertise'
import { AdvertiseItem, AdvertiseSpaceEnum } from '@/Services/advertiseApi'
import { Colors } from '@/Theme/Variables'

import AdvertiseComponent from './AdvertiseComponent'

interface AdvertiseListProps {
  leadingItem: any
  listData: any[]
  adData: AdvertiseItem[]
  space: AdvertiseSpaceEnum
}

interface AdvertiseDataType extends AdvertiseItem {
  stopShowIndex?: number
}

const AdvertiseList = ({
  leadingItem,
  adData,
  listData,
  space,
}: AdvertiseListProps) => {
  const { Gutters } = useTheme()
  const dispatch = useDispatch()

  const { userClickedAdvertise } = useSelector(
    (state: RootState) => state.advertiseStore,
  )

  const [advertiseData, setAdvertiseData] = useState<AdvertiseDataType[]>([])

  useEffect(() => {
    if (adData.length > 0) {
      setAdvertiseData(adData)
    }
  }, [adData])

  useEffect(() => {
    setAdvertiseData(prev => {
      return prev.filter(item => !userClickedAdvertise.includes(item.id))
    })
  }, [userClickedAdvertise])

  if (advertiseData.length === 0) {
    return null
  }

  const handleClickAD = (id: string) => {
    dispatch(advertiseActions.userClickedAdvertise(id))
  }

  // 每幾個項目顯示一個廣告
  const showADInterval = 8
  // 取得當前項目在列表中的 index
  const currentIndex = listData.indexOf(leadingItem)
  // 計算當前要顯示的廣告 index
  const showADItem =
    Math.floor(currentIndex / showADInterval) % advertiseData.length

  // 列表數量小於 5 且是最後一筆時，顯示第一個廣告
  if (
    listData.length < showADInterval &&
    currentIndex === listData.length - 1
  ) {
    return (
      <View style={[Gutters.tinyBMargin]}>
        <AdvertiseComponent
          data={advertiseData[0]}
          space={space}
          onClick={(id: string) => handleClickAD(id)}
        />
      </View>
    )
  }

  // 每5個項目後顯示一個廣告（排除第0個位置）
  if (
    currentIndex !== 0 &&
    (currentIndex + 1) % showADInterval === 0 &&
    !userClickedAdvertise.includes(advertiseData[showADItem].id)
  ) {
    return (
      <View style={[Gutters.tinyBMargin]}>
        <AdvertiseComponent
          data={advertiseData[showADItem]}
          space={space}
          customSponsorTagStyle={{
            backgroundColor: Colors.background.onSurface,
          }}
          onClick={handleClickAD}
        />
      </View>
    )
  }

  return null
}

export default React.memo(AdvertiseList)
