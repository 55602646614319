import { useState, useEffect } from 'react'

const useIdleMins = (mins: number) => {
  const [isIdle, setIsIdle] = useState(false)

  const idleTime = mins * 60 * 1000

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      if (isIdle) {
        setIsIdle(false)
      }

      timeoutId = setTimeout(() => {
        setIsIdle(true)
      }, idleTime)
    }

    // 監聽使用者活動的事件
    const events = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
      'wheel',
    ]

    // 初始化計時器
    resetTimer()

    events.forEach(event => {
      if (typeof window !== 'undefined') {
        //@ts-ignore
        window.addEventListener(event, resetTimer)
      }
    })

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      events.forEach(event => {
        //@ts-ignore
        window.removeEventListener(event, resetTimer)
      })
    }
  }, [idleTime, isIdle])

  return isIdle
}

export default useIdleMins
