import AsyncStorage from '@react-native-async-storage/async-storage'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { api } from '@/Services/api'
import { logApi } from '@/Services/logApi'
import { advertiseApi } from '@/Services/advertiseApi'

import theme, { ThemeState } from './Theme'
import global, { GlobalState } from './Global'
import spirit, { SpiritState } from './Spirit'
import user, { UserState } from './User'
import advertise, { AdvertiseState } from './Advertise'

const reducers = combineReducers({
  theme,
  global,
  spirit,
  user,
  advertiseStore: advertise,
  api: api.reducer,
  log: logApi.reducer,
  advertise: advertiseApi.reducer,
})

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['theme'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  // @ts-ignore
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([api.middleware, logApi.middleware, advertiseApi.middleware])

    if (__DEV__ && !process.env.JEST_WORKER_ID) {
      // const createDebugger = require('redux-flipper').default
      // middlewares.push(createDebugger())
    }

    return middlewares
  },
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export interface RootState {
  global: GlobalState
  spirit: SpiritState
  user: UserState
  theme: ThemeState
  advertiseStore: AdvertiseState
}

export const getGlobal = (state: RootState) => state.global

export { store, persistor }
