import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'
import { LoadingComponent } from '@/Components'
import spiritsApi from '@/Services/modules/spirits'
import { removeEmptyKeys } from '@/Util/global'
import { debounce } from '@mui/material'

import SectionContainer from '../components/sectionContainer'
import RatingSelector, { limitNumber } from '../components/ratingSelector'
import RangeSelector from '../components/rangeSelector'
import useGetBrandsData from '../hooks/useGetBrandsData'
import useGetPairingsData from '../hooks/useGetPairingsData'
import useGetCountriesData from '../hooks/useGetCountriesData'
import useGetRegionsData from '../hooks/useGetRegionsData'
import useGetCategoriesData from '../hooks/useGetCategoriesData'
import useGetFlavorData, { FlavorDataItem } from '../hooks/useGetFlavorData'
import BottomSubmitButton from '../components/bottomSubmitButton'
import useCheckIsAdvanceFlavor from '../hooks/useCheckIsAdvanceFlavor'

const INIT_PRICE_RANGE = {
  min: 0,
  max: 10000,
}

const INIT_RATING = 1

// 將 params 轉換為 api 需要的格式
export const convertParams = (params: any) => {
  if (typeof params === 'string') {
    return params
  }
  if (Array.isArray(params)) {
    return params.join(',')
  }
  return params
}

// 將 price 轉換為 api 需要的格式
export const convertPrice = (minPrice: number, maxPrice: number) => {
  const minPriceHasValueAndMaxPriceIsDefault =
    minPrice !== undefined && maxPrice === undefined

  const minPriceIsDefaultAndMaxPriceHasValue =
    minPrice === undefined && maxPrice !== undefined

  const minPriceAndMaxPriceIsDefault =
    Number(maxPrice) === INIT_PRICE_RANGE.max &&
    Number(minPrice) === INIT_PRICE_RANGE.min

  if (minPrice === undefined && maxPrice === undefined) {
    return undefined
  }

  if (minPriceHasValueAndMaxPriceIsDefault || minPriceAndMaxPriceIsDefault) {
    return `gte,${minPrice}`
  }

  if (minPriceIsDefaultAndMaxPriceHasValue) {
    return `lte,${maxPrice}`
  }

  return `between,${minPrice},${maxPrice}`
}

export const convertRating = (ratingValue: number) => {
  if (ratingValue === undefined) {
    return undefined
  }

  // 預設讓他全部都能找
  if (ratingValue === INIT_RATING) {
    return 'gte,0'
  }

  return `gte,${ratingValue}`
}

// 將 flavor 分為 parents 和 children
export const separateFlavors = (
  flavors: string | string[] | undefined,
  flavorsData: FlavorDataItem[],
): { parents: string[]; children: string[] } => {
  const flavorArray =
    typeof flavors === 'string' ? flavors.split(',') : flavors || []
  const parentKey = flavorsData.map((flavor: FlavorDataItem) => flavor.enName)

  const parents: string[] = []
  const children: string[] = []

  if (parentKey.length > 0) {
    for (const flavor of flavorArray) {
      if (parentKey.includes(flavor)) {
        parents.push(flavor)
      } else {
        children.push(flavor)
      }
    }
  }

  return { parents, children }
}

// 將有被選取（params）的值移到最前面
export const moveSelectedParamsToFront = (
  params: string | string[] | undefined,
  data: any,
  number: number,
) => {
  const paramsData = convertParams(params)
  // -1：表示 a 應該排在 b 前面
  // 1：表示 b 應該排在 a 前面
  // 0：表示 a 和 b 的順序不變
  const newData = data.sort((a: any, b: any) => {
    const paramsArr = paramsData?.split(',')
    const aSelected = paramsArr?.includes(a.i18nKey)
    const bSelected = paramsArr?.includes(b.i18nKey)

    if (aSelected && !bSelected) {
      return -1
    }
    if (!aSelected && bSelected) {
      return 1
    }
    return 0
  })
  return newData.slice(0, number)
}

const MainFilterModal = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { brandsData, brandDataIsLoading } = useGetBrandsData()
  const { pairingsData, pairingDataIsLoading } = useGetPairingsData()
  const { countriesData, countryDataIsLoading } = useGetCountriesData()
  const { regionsData, regionsDataIsLoading } = useGetRegionsData()
  const { categoriesData, categoriesDataIsLoading } = useGetCategoriesData()
  const { flavorsData, flavorDataIsLoading } = useGetFlavorData()

  const isAdvanceFlavor = useCheckIsAdvanceFlavor()

  const styles = getStyle()

  // 取得 filter meta data
  const { data: filterMetaData, isLoading: filterMetaDataIsLoading } =
    spiritsApi.useGetSearchMetaQuery()

  const [filterMetaDataRequest, { data: lazyFilterMetaData }] =
    spiritsApi.useLazyGetSearchMetaQuery()

  const [rating, setRating] = useState(INIT_RATING)
  const [ratingForDisplay, setRatingForDisplay] = useState(INIT_RATING)
  const [minPrice, setMinPrice] = useState(INIT_PRICE_RANGE.min)
  const [maxPrice, setMaxPrice] = useState(INIT_PRICE_RANGE.max)
  const [scrollComponentLoading, setScrollComponentLoading] = useState(false)

  const disabledSubmitButton = useMemo(() => {
    return (
      lazyFilterMetaData?.data?.hits === 0 || filterMetaData?.data?.hits === 0
    )
  }, [lazyFilterMetaData, filterMetaData])

  const flavorParentOrChildren = useMemo(() => {
    const result = []
    // 如果是進階，外面只需要顯示 children 風味資料
    if (isAdvanceFlavor) {
      for (const flavor of flavorsData) {
        result.push(...flavor.children)
      }
    } else {
      for (const flavor of flavorsData) {
        result.push({
          zhName: flavor.zhName,
          enName: flavor.enName,
          count: flavor.count,
          i18nKey: flavor.i18nKey,
        })
      }
    }
    return result
  }, [flavorsData, isAdvanceFlavor])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.SpiritsCategoriesContainer)
    }
  }, [navigation])

  const handleRemoveParams = useCallback(() => {
    const paramsObj = {
      category: undefined,
      flavor: undefined,
      brand: undefined,
      country: undefined,
      region: undefined,
      pairings: undefined,
      maxPrice: undefined,
      minPrice: undefined,
      rating: undefined,
    }

    setMinPrice(INIT_PRICE_RANGE.min)
    setMaxPrice(INIT_PRICE_RANGE.max)
    navigation.setParams(paramsObj)
  }, [navigation])

  const resetButtonDisabled = useMemo(() => {
    if (route.params) {
      return Object.values(route.params).some(value => value !== undefined)
        ? false
        : true
    }
    return true
  }, [route.params])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRatingChange = useCallback(
    debounce((value: number) => {
      setRating(value)
      navigation.setParams({ rating: value })
    }, 100),
    [navigation],
  )

  const handleRatingChangeForDisplay = useCallback((value: number) => {
    setRatingForDisplay(value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePriceRangeChange = useCallback(
    debounce((values: [number, number]) => {
      setMinPrice(values[0])
      setMaxPrice(values[1])

      const convertMinPrice =
        values[0] === INIT_PRICE_RANGE.min ? undefined : values[0]
      const convertMaxPrice =
        values[1] === INIT_PRICE_RANGE.max ? undefined : values[1]

      navigation.setParams({
        minPrice: convertMinPrice,
        maxPrice: convertMaxPrice,
      })
    }, 500),
    [],
  )

  // 需轉換一下，不然第一次網址抓下來是 string
  const handleGetRouteParams = useCallback(
    (key: string) => {
      if (route?.params?.[key]) {
        return typeof route.params[key] === 'string'
          ? route.params[key].split(',')
          : route.params[key]
      }
      return []
    },
    [route],
  )

  const handleSubmit = useCallback(() => {
    const convertRedirectRating = (value: number | string) =>
      Number(value) === INIT_RATING ? undefined : value

    const convertMinPrice = (min: number | string) =>
      Number(min) === INIT_PRICE_RANGE.min ? undefined : min

    const convertMaxPrice = (max: number | string) =>
      Number(max) === INIT_PRICE_RANGE.max ? undefined : max

    if (route?.params?.redirectTo) {
      navigation.navigate(route?.params?.redirectTo, {
        ...route.params,
        rating: convertRedirectRating(rating || route.params?.rating),
        minPrice: convertMinPrice(
          minPrice || route.params?.minPrice || INIT_PRICE_RANGE.min,
        ),
        maxPrice: convertMaxPrice(
          maxPrice || route.params?.maxPrice || INIT_PRICE_RANGE.max,
        ),
      })
      return
    } else {
      navigation.goBack()
    }
  }, [route.params, navigation, rating, minPrice, maxPrice])

  // 設定網址上的參數
  useEffect(() => {
    setRating(Number(route?.params?.rating) || INIT_RATING)
    setRatingForDisplay(Number(route?.params?.rating) || INIT_RATING)
    setMinPrice(route?.params?.minPrice || INIT_PRICE_RANGE.min)
    setMaxPrice(route?.params?.maxPrice || INIT_PRICE_RANGE.max)
  }, [route.params])

  // 參數變動時，取得新的 filter meta data
  useEffect(() => {
    const { parents, children } = separateFlavors(
      route?.params?.flavor,
      flavorsData,
    )
    const paramsObj = {
      averageRating: convertRating(route?.params?.rating),
      marketPriceZh: convertPrice(
        route?.params?.minPrice,
        route?.params?.maxPrice,
      ),
      category: convertParams(route?.params?.category),
      'flavors.slug': convertParams(parents),
      'flavors.children': convertParams(children),
      brand: convertParams(route?.params?.brand),
      country: convertParams(route?.params?.country),
      pairings: convertParams(route?.params?.pairings),
      region: convertParams(route?.params?.region),
    }

    filterMetaDataRequest(removeEmptyKeys(paramsObj))
  }, [route.params, filterMetaDataRequest, flavorsData])

  // workaround 讓數字不要一直跳
  useEffect(() => {
    if (scrollComponentLoading) {
      setTimeout(() => {
        setScrollComponentLoading(false)
      }, 1500)
    }
  }, [scrollComponentLoading])

  if (
    filterMetaDataIsLoading ||
    brandDataIsLoading ||
    pairingDataIsLoading ||
    countryDataIsLoading ||
    regionsDataIsLoading ||
    categoriesDataIsLoading ||
    flavorDataIsLoading
  ) {
    return (
      <View style={[styles.container]}>
        <LoadingComponent />
      </View>
    )
  }

  return (
    <View style={[Layout.fill]}>
      <ScrollView style={[styles.container]}>
        {/* header */}
        <View style={[styles.header]}>
          <TouchableOpacity onPress={handleGoBack}>
            <Image
              style={[styles.closeIcon]}
              source={Images.close}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={[
              Fonts.weight600,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            篩選
          </Text>
          <TouchableOpacity
            onPress={handleRemoveParams}
            disabled={resetButtonDisabled}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                {
                  color: resetButtonDisabled
                    ? Colors.fontText.disabled
                    : Colors.primary,
                },
              ]}
            >
              重設所有
            </Text>
          </TouchableOpacity>
        </View>
        {/* header */}

        {/* container  */}
        <View
          style={[
            Layout.fill,
            Gutters.regularHPadding,
            Gutters.regularVPadding,
          ]}
        >
          <View style={[Gutters.regularBMargin]}>
            <Text style={styles.sectionTitle}>價格</Text>
            <RangeSelector
              min={INIT_PRICE_RANGE.min}
              max={INIT_PRICE_RANGE.max}
              initialLow={INIT_PRICE_RANGE.min}
              initialHigh={INIT_PRICE_RANGE.max}
              step={1}
              onValuesChange={value => {
                handlePriceRangeChange(value)
                setScrollComponentLoading(true)
              }}
              value={[minPrice, maxPrice]}
            />
          </View>
          <View>
            <View
              style={[
                Layout.row,
                Layout.justifyContentBetween,
                Layout.alignItemsCenter,
              ]}
            >
              <Text style={styles.sectionTitle}>評分</Text>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  source={Images.search_filter_rating_star}
                  style={[Layout.iconSize16, Gutters.miniRMargin]}
                />
                {rating === limitNumber.min ? (
                  <Text
                    style={[
                      Fonts.weight400,
                      Fonts.size16,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    Any
                  </Text>
                ) : (
                  <Text
                    style={[
                      Fonts.weight400,
                      Fonts.size16,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >{`${ratingForDisplay} - 5.0`}</Text>
                )}
              </View>
            </View>
            <RatingSelector
              min={1}
              max={5}
              step={0.1}
              value={rating}
              onValueChange={value => {
                handleRatingChange(value)
                handleRatingChangeForDisplay(value)
                setScrollComponentLoading(true)
              }}
            />
          </View>
          <SectionContainer
            title="酒類"
            type="category"
            itemsData={categoriesData || []}
            selectedItems={handleGetRouteParams('category')}
            canOpen={false}
            showMoreButton={false}
            onMorePress={() => {}}
          />
          <SectionContainer
            title="風味"
            type="flavor"
            itemsData={moveSelectedParamsToFront(
              route.params?.flavor,
              flavorParentOrChildren,
              6,
            )}
            selectedItems={handleGetRouteParams('flavor')}
            canOpen={true}
            onMorePress={() => {
              navigation.navigate(PagesEnum.FlavorFilterModal, {
                ...route.params,
              })
            }}
          />
          <SectionContainer
            title="品牌"
            type="brand"
            itemsData={moveSelectedParamsToFront(
              route.params?.brand,
              brandsData,
              6,
            )}
            selectedItems={handleGetRouteParams('brand')}
            canOpen={true}
            onMorePress={() => {
              navigation.navigate(PagesEnum.BrandFilterModal, {
                ...route.params,
              })
            }}
            needEnName={true}
          />
          <SectionContainer
            title="國家"
            type="country"
            itemsData={moveSelectedParamsToFront(
              route.params?.country,
              countriesData,
              6,
            )}
            selectedItems={handleGetRouteParams('country')}
            canOpen={true}
            onMorePress={() => {
              navigation.navigate(PagesEnum.CountryFilterModal, {
                ...route.params,
              })
            }}
          />
          <SectionContainer
            title="地區"
            type="region"
            itemsData={regionsData || []}
            selectedItems={handleGetRouteParams('region')}
            canOpen={true}
            showMoreButton={false}
            onMorePress={() => {}}
          />
          <SectionContainer
            title="餐酒搭配"
            type="pairings"
            itemsData={moveSelectedParamsToFront(
              route.params?.pairings,
              pairingsData,
              6,
            )}
            selectedItems={handleGetRouteParams('pairings')}
            canOpen={true}
            onMorePress={() => {
              navigation.navigate(PagesEnum.PairingFilterModal, {
                ...route.params,
              })
            }}
          />
        </View>
      </ScrollView>
      <BottomSubmitButton
        handleSubmit={handleSubmit}
        disabled={disabledSubmitButton || scrollComponentLoading}
        titleColor={
          scrollComponentLoading ? Colors.fontText.light.primary2 : undefined
        }
        title={
          scrollComponentLoading
            ? '載入中...'
            : `顯示 ${
                lazyFilterMetaData?.data?.hits?.toLocaleString() ||
                filterMetaData?.data?.hits?.toLocaleString() ||
                0
              } 瓶酒`
        }
      />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.background.surface,
      height: Dimensions.get('window').height,
      paddingBottom: 150,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 30,
      borderBottomWidth: 1,
      borderColor: Colors.background.top,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: '700',
      color: Colors.fontText.light.primary2,
      marginBottom: 16,
    },
  })

export default MainFilterModal
