import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { TextInput } from 'react-native-paper'
import { FadeInView, Header } from '@/Components'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { SignUpBasketRequestFields } from '@/Services/modules/users/user.type'
import { globalActions } from '@/Store/Global'
import { RootState } from '@/Store'
import { Validation } from '@/Util/validation'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
// import { useTranslation } from 'react-i18next'

const COUNT_DOWN = 125

const SocialOTPContainer = ({ navigation, route }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()
  const { register } = useSelector((state: RootState) => state.user)

  const [
    getOTPCodeRequest,
    {
      isSuccess: OTPCodeIsSuccess,
      isError: OTPCodeIsError,
      error: OTPCodeError,
      reset: OTPCodeReset,
    },
  ] = userApi.useGetOtpCodeMutation()

  const [
    signUpBasket,
    {
      data: signUpBasketData,
      error: signUpBasketError,
      isError: signUpBasketIsError,
      isSuccess: signUpBasketIsSuccess,
      reset: signUpBasketReset,
    },
  ] = userApi.useSignUpBasketMutation()

  const [email, setEmail] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [otpCounters, setOtpCounters] = useState(COUNT_DOWN)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const styles = stylesSheet()

  // 如果沒有帳密資訊就跳回註冊頁面
  useEffect(() => {
    if (!register?.account?.length) {
      navigation.navigate(PagesEnum.EmailRegisterContainer)
    }
  }, [register?.account?.length, navigation])

  // otp 錯誤流程
  useEffect(() => {
    if (OTPCodeIsError) {
      const errorData = OTPCodeError as any
      const { message } = errorData?.data?.errors?.[0]
      if (message === 'InvalidAccount') {
        setErrorMessage('電子郵件無效')
        return
      }
      if (message === 'accountIsAlreadyExisted') {
        setErrorMessage('電子郵件已註冊')
        return
      }

      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '發送驗證碼失敗，請稍後再嘗試',
          type: 'error',
        }),
      )
    }
  }, [OTPCodeError, OTPCodeIsError, dispatch])

  // 送出 opt 後，開始倒數 125 秒 才能再次發送
  useEffect(() => {
    if (OTPCodeIsSuccess) {
      let countDown = COUNT_DOWN
      const timer = setInterval(() => {
        setOtpCounters(prev => prev - 1)
        countDown--
        if (countDown === 0) {
          clearInterval(timer)
          setOtpCounters(0)
        }
      }, 1000)

      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '已成功寄出驗證碼，請至信箱收取',
          type: 'success',
          showIcon: false,
        }),
      )

      return () => clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTPCodeIsSuccess, OTPCodeReset, dispatch])

  // 檢查驗證碼是否正確
  useEffect(() => {
    if (signUpBasketIsError) {
      const errorData = signUpBasketError as any
      const { message } = errorData?.data?.errors?.[0]
      // 註冊相關資料保存時間已過期，請使用者重新註冊
      if (message === 'OldBasketDataExpired') {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '註冊相關資料保存時間已過期，重新註冊',
            type: 'error',
          }),
        )
        navigate(PagesEnum.Main, {
          screen: PagesEnum.EmailRegisterContainer,
        })
        return
      }
      if (message === 'The fields.account otp code field format is invalid.') {
        setErrorMessage('驗證碼格式錯誤')
      }
      if (message === 'otpCodeVerifyFailed') {
        setErrorMessage('驗證碼無效')
      }
      if (message === 'InviteCodeMaxUsagesExceed') {
        setErrorMessage('兌換已滿')
      }
    }
  }, [dispatch, signUpBasketError, signUpBasketIsError])

  // 成功後，跳轉到填寫基本資料頁面
  useEffect(() => {
    if (signUpBasketIsSuccess) {
      navigation.navigate(PagesEnum.SocialBasicInfoContainer, {
        basket: signUpBasketData?.data?.basket || '',
      })
      OTPCodeReset()
      signUpBasketReset()
    }
  }, [
    OTPCodeReset,
    navigation,
    signUpBasketIsSuccess,
    signUpBasketReset,
    signUpBasketData?.data?.basket,
  ])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: PagesEnum.EmailLoginContainer,
    })
  }, [navigation])

  const keyOTPCTAButtonDisabled = !otpCode?.length ? '#D9D9D9' : Colors.primary

  // 寄送驗證碼 submit
  const handleSubmitOtpCode = () => {
    if (Validation.isEmail(email)) {
      getOTPCodeRequest({ type: 'register', account: email })
      setOtpCounters(COUNT_DOWN)
    } else {
      setErrorMessage('電子郵件格式錯誤')
    }
  }

  // 確認驗證碼 submit
  const handleSubmitKeyOtpCode = () => {
    const payload: Partial<SignUpBasketRequestFields> = {
      account: email,
      accountOtpCode: otpCode,
    }

    signUpBasket({
      oldBasket: route?.params?.basket,
      fields: {
        ...payload,
      },
    })
  }

  // 寄送驗證碼
  const renderSentOTP = () => {
    return (
      <View style={[Gutters.smallTPadding]}>
        {/* 輸入電子郵件地址欄位 */}
        <FadeInView duration={550} style={Gutters.regularHPadding}>
          <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
            <Image
              source={Images.app_icon}
              style={[Layout.iconSize40, { borderRadius: 10 }]}
              resizeMode="contain"
            />
            <Text
              style={[
                Fonts.size20,
                Fonts.weight700,
                Gutters.smallVMargin,
                { color: Colors.fontText.light.primary1 },
              ]}
            >
              發送驗證碼到您的電子郵件
            </Text>
            <TextInput
              label="電子郵件地址"
              autoFocus={true}
              value={email}
              style={[{ color: Colors.white }, styles.textInput]}
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor={Colors.primary}
              theme={{
                colors: {
                  onSurfaceVariant: Colors.fontText.light.primary3,
                  error: Colors.snackbar.error,
                  outline: Colors.background.top,
                },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              error={!!errorMessage}
              onChangeText={(text: string) => {
                if (errorMessage) {
                  setErrorMessage('')
                }
                setEmail(text)
              }}
            />
            {!!errorMessage && (
              <Text
                style={[
                  Gutters.tinyVMargin,
                  {
                    color: Colors.snackbar.error,
                  },
                ]}
              >
                {errorMessage}
              </Text>
            )}
          </View>
        </FadeInView>
      </View>
    )
  }

  // 寄送驗證碼 CTA Button
  const renderSentOTPCTAButton = () => {
    return (
      <View style={[Gutters.regularHPadding, styles.ctaButtonContainer]}>
        <TouchableOpacity
          disabled={!email?.length}
          style={[
            Layout.rowCenter,
            styles.ctaButton,
            {
              backgroundColor: !email?.length ? '#D9D9D9' : Colors.primary,
            },
          ]}
          onPress={handleSubmitOtpCode}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              {
                color: !email?.length
                  ? Colors.gray
                  : Colors.fontText.dark.primary2,
              },
            ]}
          >
            發送
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  // 輸入驗證碼
  const renderKeyOTP = () => {
    return (
      <View style={[Gutters.smallTPadding]}>
        {/* 輸入帳密欄位 */}
        <FadeInView duration={550} style={Gutters.regularHPadding}>
          <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
            <Image
              source={Images.app_icon}
              style={[Layout.iconSize40, { borderRadius: 10 }]}
              resizeMode="contain"
            />
            <Text
              style={[
                Fonts.size20,
                Fonts.weight700,
                Gutters.smallVMargin,
                { color: Colors.fontText.light.primary1 },
              ]}
            >
              輸入驗證碼
            </Text>
            <TextInput
              label="驗證碼"
              autoFocus={true}
              value={otpCode}
              style={[{ color: Colors.white }, styles.textInput]}
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor={Colors.primary}
              keyboardType={'number-pad'}
              theme={{
                colors: {
                  onSurfaceVariant: Colors.fontText.light.primary3,
                  error: Colors.snackbar.error,
                  outline: Colors.background.top,
                },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              error={!!errorMessage}
              onChangeText={(text: string) => {
                if (errorMessage) {
                  setErrorMessage('')
                }
                setOtpCode(text)
              }}
            />
            {!!errorMessage && (
              <Text
                style={[
                  Gutters.tinyVMargin,
                  {
                    color: Colors.snackbar.error,
                  },
                ]}
              >
                {errorMessage}
              </Text>
            )}
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                Gutters.tinyTMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              如果沒有收到驗證碼，請點此{' '}
              <TouchableOpacity
                disabled={otpCounters !== 0}
                onPress={handleSubmitOtpCode}
              >
                <Text
                  style={[{ color: Colors.primary }]}
                >{`重新發送（${otpCounters}）`}</Text>
              </TouchableOpacity>
            </Text>
          </View>
        </FadeInView>
      </View>
    )
  }

  // 輸入驗證碼 CTA Button
  const renderKeyOTPCTAButton = () => {
    return (
      <View style={[Gutters.regularHPadding, styles.ctaButtonContainer]}>
        <TouchableOpacity
          disabled={!otpCode?.length}
          style={[
            Layout.rowCenter,
            styles.ctaButton,
            { backgroundColor: keyOTPCTAButtonDisabled },
          ]}
          onPress={handleSubmitKeyOtpCode}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              {
                color: !otpCode?.length
                  ? Colors.gray
                  : Colors.fontText.dark.primary2,
              },
            ]}
          >
            驗證並進行下一步
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <ScrollView style={[Layout.fill]}>
        <Header
          title={OTPCodeIsSuccess ? '註冊（2/3）' : '註冊（1/3）'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        {OTPCodeIsSuccess ? renderKeyOTP() : renderSentOTP()}
      </ScrollView>
      {OTPCodeIsSuccess ? renderKeyOTPCTAButton() : renderSentOTPCTAButton()}
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
    ctaButtonContainer: {
      width: '100%',
      position: 'absolute',
      bottom: 50,
      left: 0,
    },
    ctaButton: {
      height: 48,
      borderRadius: 8,
      width: '100%',
    },
  })

export default SocialOTPContainer
