import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import {
  View,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Keyboard,
  TouchableOpacity,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  SpiritCard,
  WarningSignComponent,
  AdvertiseList,
  AdvertiseComponent,
} from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { removeEmptyKeys } from '@/Util/global'
import { RootState, getGlobal } from '@/Store'
import { navigate } from '@/Navigators/utils'
import { spiritActions } from '@/Store/Spirit'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { advertiseApi, AdvertiseSpaceEnum } from '@/Services/advertiseApi'

import {
  convertParams,
  convertPrice,
  convertRating,
  separateFlavors,
} from './filterModal/MainFilterModal'
import useGetFlavorData from './hooks/useGetFlavorData'
import SortModal, { SORT_BTN_ARR } from './components/sortModal'

const FilterSearchListContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.theme)
  const { searchList: searchListData } = useSelector(
    (state: RootState) => state.spirit,
  )

  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  const flatListRef = useRef<any>()

  const [
    getSearchSpiritsRequest,
    {
      isLoading: isGetSearchSpiritsLoading,
      isFetching: isGetSearchSpiritsFetching,
    },
  ] = spiritsApi.useLazyGetSearchSpiritsQuery()

  // 廣告資料
  const { data: advertiseData } = advertiseApi.useGetAdvertiseInfoQuery(
    {
      space: [
        AdvertiseSpaceEnum.EXPLORE_LIST,
        AdvertiseSpaceEnum.EXPLORE_TITLE,
      ],
    },
    { refetchOnMountOrArgChange: 10 },
  )

  const { flavorsData, flavorIsFinish } = useGetFlavorData()

  const [filters, setFilters] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [sortValue, setSortValue] = useState<{
    label: string
    value: string
  }>(SORT_BTN_ARR[0])
  const [visible, setVisible] = useState(false)

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const handleSort = () => {
    setSortValue(sortValue)
  }

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    dispatch(spiritActions.clearSearchList())
    setPage(1)

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.SpiritsCategoriesContainer)
    }
  }, [dispatch, navigation])

  const handleGoFilter = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
      redirectTo: PagesEnum.FilterSearchListContainer,
    })
  }, [navigation, route.params])

  // 將 metadata 放入 filters 中
  useLayoutEffect(() => {
    if (!flavorIsFinish) {
      return
    }

    if (route?.params?.redirectTo) {
      navigation.setParams({
        redirectTo: undefined,
      })
    }

    dispatch(spiritActions.clearSearchList())
    setPage(1)

    const { parents, children } = separateFlavors(
      route?.params?.flavor,
      flavorsData,
    )

    const paramsObj = {
      averageRating: convertRating(route?.params?.rating),
      marketPriceZh: convertPrice(
        route?.params?.minPrice,
        route?.params?.maxPrice,
      ),
      category: convertParams(route?.params?.category),
      categoryNot: convertParams(route?.params?.categoryNot),
      'flavors.slug': convertParams(parents),
      'flavors.children': convertParams(children),
      brand: convertParams(route?.params?.brand),
      country: convertParams(route?.params?.country),
      pairings: convertParams(route?.params?.pairings),
      region: convertParams(route?.params?.region),
    }

    const paramsSort =
      SORT_BTN_ARR.find(item => item.value === route?.params?.sorts) ||
      SORT_BTN_ARR[0]

    // 設定 排序以及filter的 params
    setSortValue(paramsSort)
    setFilters(removeEmptyKeys(paramsObj))

    // 取得資料
    dispatch(spiritActions.clearSearchList())
    getSearchSpiritsRequest(
      removeEmptyKeys({
        filters: removeEmptyKeys(paramsObj),
        sorts: paramsSort.value,
        page: 1,
      }),
    )
  }, [
    route?.params,
    flavorsData,
    dispatch,
    getSearchSpiritsRequest,
    flavorIsFinish,
    navigation,
  ])

  const handleFetchMoreData = useCallback(() => {
    const isNeedFetch =
      searchListData.meta.currentPage !== searchListData.meta.totalPages &&
      page !== searchListData.meta.totalPages

    if (isNeedFetch) {
      const nextPage = page + 1
      const params = {
        filters: filters || '',
        sorts: sortValue.value,
        page: nextPage,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
      setPage(nextPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getSearchSpiritsRequest, searchListData.meta, sortValue.value])

  // 計算篩選有幾個篩選條件
  const convertFilterItemNumber = () => {
    if (!route.params) {
      return 0
    }
    const category = convertParams(route.params?.category)
    const flavor = convertParams(route.params?.flavor)
    const brand = convertParams(route.params?.brand)
    const country = convertParams(route.params?.country)
    const region = convertParams(route.params?.region)
    const pairings = convertParams(route.params?.pairings)

    let number = 0

    if (category) {
      number += category.split(',').length
    }
    if (flavor) {
      number += flavor.split(',').length
    }
    if (brand) {
      number += brand.split(',').length
    }
    if (country) {
      number += country.split(',').length
    }
    if (region) {
      number += region.split(',').length
    }
    if (pairings) {
      number += pairings.split(',').length
    }
    return number
  }

  const handleClickCard = (id: string, titleUrlSlug: string) => {
    navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug,
      id,
    })
  }

  const handleSortSubmit = (value: string) => {
    hideModal()
    navigation.navigate(PagesEnum.FilterSearchListContainer, {
      ...route.params,
      sorts: value,
    })
    const params = {
      filters: filters || '',
      sorts: value,
      page: 1,
    }

    setPage(1)
    dispatch(spiritActions.clearSearchList())
    getSearchSpiritsRequest(removeEmptyKeys(params))
  }

  const renderCardList = ({ item }: any) => {
    const {
      title,
      id,
      imageUrl,
      ratings,
      titleUrlSlug,
      brand,
      marketPrice,
      flavors,
    } = item
    return (
      <SpiritCard
        key={id}
        id={id}
        onClick={() => handleClickCard(id, titleUrlSlug)}
        imageUrl={imageUrl}
        title={title}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
        marketPrice={marketPrice}
        flavors={flavors?.topN}
        showPrice={!!marketPrice}
        showFlavors={!!flavors?.topN?.length}
      />
    )
  }

  const renderEmptyView = () => {
    if (isGetSearchSpiritsFetching || isGetSearchSpiritsLoading) {
      return (
        <View style={[Layout.colCenter, Layout.fullHeight]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        {searchListData.data?.length === 0 && (
          <>
            <Image
              source={Images.search_list_empty}
              resizeMode="contain"
              style={[Gutters.regularBMargin, styles.emptyIcon]}
            />
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              抱歉，找不到相似的結果
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.regularBMargin,
                { color: Colors.secondaryText },
              ]}
            >
              請嘗試搜尋其他烈酒
            </Text>
          </>
        )}
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <FadeInView duration={500} style={Layout.fill}>
        <Header
          title={'探索'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Gutters.regularHPadding,
            Gutters.regularBPadding,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <TouchableOpacity style={[styles.sortButton]} onPress={showModal}>
            <Image
              source={Images.search_filter_sort_icon}
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {sortValue.label}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.sortButton]}
            onPress={handleGoFilter}
          >
            <Image
              source={Images.search_filter_filter_icon}
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              篩選
            </Text>
            <View style={[styles.sortItemNumber]}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                {convertFilterItemNumber()}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={[Layout.fill]}>
          {isGetSearchSpiritsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                ref={flatListRef}
                data={searchListData.data || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item.id.toString()}
                renderItem={renderCardList}
                onScroll={() => Keyboard?.dismiss()}
                refreshing
                scrollEnabled={!productImageLightBox?.visible}
                maxToRenderPerBatch={10}
                onEndReached={() => {
                  handleFetchMoreData()
                }}
                onEndReachedThreshold={0.1}
                getItemLayout={(data, index) => {
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListEmptyComponent={renderEmptyView}
                // TODO: 頂部效果
                ListHeaderComponent={() => {
                  const data =
                    advertiseData?.data?.[AdvertiseSpaceEnum.EXPLORE_TITLE]?.[0]

                  if (data && searchListData.data.length > 0) {
                    return (
                      <View style={[Gutters.tinyBMargin]}>
                        <AdvertiseComponent
                          data={data}
                          space={AdvertiseSpaceEnum.EXPLORE_TITLE}
                          customCardStyle={{
                            backgroundColor: Colors.background.default,
                          }}
                        />
                      </View>
                    )
                  }
                }}
                ItemSeparatorComponent={({ leadingItem }) => {
                  return (
                    <AdvertiseList
                      leadingItem={leadingItem}
                      listData={searchListData.data}
                      adData={
                        advertiseData?.data?.[
                          AdvertiseSpaceEnum.EXPLORE_LIST
                        ] || []
                      }
                      space={AdvertiseSpaceEnum.EXPLORE_LIST}
                    />
                  )
                }}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
      {visible && (
        <SortModal
          visible={visible}
          hideModal={hideModal}
          onSort={handleSort}
          sortValue={sortValue}
          setSortValue={setSortValue}
          handleSortSubmit={handleSortSubmit}
        />
      )}
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    emptyIcon: {
      width: 150,
      height: 150,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    filter: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
    },
    sortButton: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.background.top,
      padding: 8,
      marginRight: 16,
    },
    sortItemNumber: {
      width: 18,
      height: 18,
      backgroundColor: Colors.primary,
      borderRadius: 18,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
  })

export default FilterSearchListContainer
