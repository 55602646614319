import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AdvertiseState = {
  userClickedAdvertise: string[]
}

const initialState = {
  userClickedAdvertise: [],
}

const slice = createSlice({
  name: 'advertise',
  initialState: initialState as AdvertiseState,
  reducers: {
    userClickedAdvertise: (state, action: PayloadAction<string>) => {
      state.userClickedAdvertise.push(action.payload)
    },
  },
})

export const advertiseReducer = slice.reducer
export const advertiseActions = slice.actions

export default slice.reducer
